import React, { useState } from "react";
import Footer from "../components/Footer";
import DonorNav from "./DonorNav";
import ReactLoading from "react-loading";

const UserAddBlood = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const Loader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="#d9534f"
            height={100}
            width={50}
          />
        </div>
      ) : (
        <>
        <div id="wrapper" onClick={Loader}>
          <DonorNav />
          <div id="page-wrapper">
            <div className="row">
              <div className=".col-lg-12">
                <h1 className="page-header">Donate Blood Section</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Please fill up the form below:
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <form
                          // role="form"
                          action="useraddedblood.php"
                          method="POST"
                        >
                          <div className="form-group">
                            <label>Enter Full Name</label>
                            <input
                              className="form-control"
                              placeholder="Enter Name"
                              type="text"
                              name="name"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Gender [ M/F ]</label>
                            <input
                              className="form-control"
                              placeholder="M for Male & F for Female"
                              type="text"
                              name="gender"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Enter D.O.B</label>
                            <input
                              className="form-control"
                              type="date"
                              name="dob"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Enter Weight</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="KG"
                              name="weight"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Enter Blood Group</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Example: B+, O-, B-"
                              name="bloodgroup"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Enter Address</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Full Address"
                              name="address"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Enter Contact Number</label>
                            <input
                              className="form-control"
                              type="number"
                              name="contact"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Blood Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              name="bloodqty"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Collection Date</label>
                            <input
                              className="form-control"
                              type="date"
                              name="collection"
                              required
                            />
                          </div>

                          <button type="submit" className="btn btn-success">
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
        </>
      )}
    </>
  );
};

export default UserAddBlood;
