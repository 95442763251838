import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const AdminNav = ({ title, icon }) => {
  const [search, setSearch] = useState("");
  const [change, setChange] = useState(false);
  const [Blood, setBlood] = useState(true);
  const [Announcements, setAnnouncements] = useState(false);
  const [Campaigns, setCampaigns] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    // e.preventDefault();
    navigate(`/admin-dashboard/search?name=${search}`);
    setSearch("");
  };
  return (
    <>
      <nav
        className="navbar navbar-default navbar-static-top"
        role="navigation"
        style={{ marginBottom: "0" }}
      >
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link
            className="navbar-brand"
            to="/admin-dashboard"
            style={{ color: "darkgreen", fontFamily: "cursive" }}
          >
            <i className={icon}> </i>
            {title}
          </Link>
        </div>

        <ul className="nav navbar-top-links navbar-right mhide">
          <li className="dropdown">
            <Link className="dropdown-toggle" data-toggle="dropdown" to="#">
              <i className="fa fa-user fa-fw"></i>
              <i className="fa fa-caret-down"></i>
            </Link>
            <ul
              className="dropdown-menu dropdown-user"
              onClick={() => setChange((change) => !change)}
            >
              {change ? (
                <li>
                  <Link to="/profile">
                    <i className="fa fa-user fa-fw"></i> Profile
                  </Link>
                  <li className="divider"></li>
                  <Link to="/logout">
                    <i className="fa fa-sign-out fa-fw"></i> Logout
                  </Link>
                </li>
              ) : (
                <li>
                  <Link to="/profile">
                    <i className="fa fa-user fa-fw"></i> Profile
                  </Link>
                  <li className="divider"></li>
                  <Link to="/logout">
                    <i className="fa fa-sign-out fa-fw"></i> Logout
                  </Link>
                </li>
              )}
            </ul>
          </li>
        </ul>

        <div className="navbar-default sidebar" role="navigation">
          <div className="sidebar-nav navbar-collapse collapse">
            <ul className="nav admin-nav" id="side-menu">
              <li className="sidebar-search">
                <div className="input-group custom-search-form">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-default"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </li>
              <li>
                <Link to="/admin-dashboard">
                  <i className="fa fa-dashboard fa-fw"></i> Dashboard
                </Link>
              </li>
              <li onClick={() => setBlood(!Blood)}>
                <Link to="">
                  <i className="fa fa-heartbeat fa-fw"></i> Blood Collection
                  Details
                  <span style={{ float: "right" }}>
                    {Blood ? (
                      <span className="fa fa-angle-down"></span>
                    ) : (
                      <span className="fa fa-angle-left"></span>
                    )}
                  </span>
                </Link>
                {Blood ? (
                  <ul className="nav nav-second-level">
                    <li>
                      <Link to="/add-blood">Add Blood</Link>
                    </li>
                    <li>
                      <Link to="/views-blood">View Blood</Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li>
                <Link to="/add-donor">
                  <i className="fa fa-table fa-user-plus fa-fw"></i> Add Donor
                </Link>
              </li>
              <li>
                <Link to="/view-donor">
                  <i className="fa fa-edit fa-eye fa-fw"></i> View Donor Details
                </Link>
              </li>
              <li>
                <Link to="/edit-donor">
                  <i className="fa fa-edit fa-fw"></i> Edit Donor Details
                </Link>
              </li>
              <li>
                <Link to="/delete-donor">
                  <i className="fa fa-user-times fa-fw"></i> Delete Donor
                  Details
                </Link>
              </li>
              <li onClick={() => setAnnouncements(!Announcements)}>
                <Link to="">
                  <i className="fa fa-bullhorn fa-fw"></i> Announcements
                  <span style={{ float: "right" }}>
                    {Announcements ? (
                      <span className="fa fa-angle-down"></span>
                    ) : (
                      <span className="fa fa-angle-left"></span>
                    )}
                  </span>
                </Link>
                {Announcements ? (
                  <ul className="nav nav-second-level">
                    <li>
                      <Link to="/make-announcement">Make Announcement</Link>
                    </li>
                    <li>
                      <Link to="/views-announcement">View Announcement</Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>

              <li onClick={() => setCampaigns(!Campaigns)}>
                <Link to="">
                  <i className="fa fa-flag fa-fw"></i> Campaigns
                  <span style={{ float: "right" }}>
                    {Campaigns ? (
                      <span className="fa fa-angle-down"></span>
                    ) : (
                      <span className="fa fa-angle-left"></span>
                    )}
                  </span>
                </Link>
                {Campaigns ? (
                  <ul className="nav nav-second-level">
                    <li>
                      <Link to="/new-campaign">New Campaign</Link>
                    </li>
                    <li>
                      <Link to="/views-campaigns">View Campaign</Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <link href="../vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
      <link href="../vendor/metisMenu/metisMenu.min.css" rel="stylesheet" />
      <link href="../dist/css/sb-admin-2.css" rel="stylesheet" />
      <link href="../vendor/morrisjs/morris.css" rel="stylesheet" />
      <link
        href="../vendor/font-awesome/css/font-awesome.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link rel="stylesheet" href="../icofont/icofont.min.css" />
      <link
        href="http://fonts.googleapis.com/css?family=Open+Sans:400,700,800"
        rel="stylesheet"
        type="text/css"
      />
      <script
        type="text/javascript"
        src="https://www.gstatic.com/charts/loader.js"
      ></script>
      <script src="../vendor/jquery/jquery.min.js"></script>
      <script src="../vendor/bootstrap/js/bootstrap.min.js"></script>
      <script src="../vendor/metisMenu/metisMenu.min.js"></script>
      <script src="../vendor/raphael/raphael.min.js"></script>
      <script src="../vendor/morrisjs/morris.min.js"></script>
      <script src="../data/morris-data.js"></script>
      <script src="../dist/js/sb-admin-2.js"></script>
    </>
  );
};

export default AdminNav;
