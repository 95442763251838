import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
// import data from "../data/data.json";
// import DeleteArticle from "./DeleteArticle";

const LatestNews = () => {
  const [LatestNews, setLatestNews] = useState([]);
  useEffect(() => {
    const LatestNewsRef = collection(db, "LatestNews");
    const q = query(LatestNewsRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const LatestNews = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLatestNews(LatestNews);
      console.log(LatestNews);
    });
  }, []);
  return (
    <>
      <div className="container">
        <div className="row hed">
          <h2>Latest News</h2>
          <Link to="/news">
            <h5 style={{ float: "right" }}>
              View All
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
            </h5>
          </Link>
        </div>

        <div className="row">
          {LatestNews.length === 0 ? (
            <p className="p-5">Try again later Error found 404 !  </p>
          ) : (
            LatestNews.map(
              ({ id, title, description, image, views, createdAt }) => (
                <div className="col-sm-6" key={id}>
                  <div className="col-sm-6 imsec">
                    <img className="shado-1" alt="" src={image} />
                  </div>
                  <div className="col-sm-6 nws">
                    <div className="topp">
                      <strong className="mnt">
                        <i className="fa fa-calendar"></i>
                        {createdAt.toDate().toDateString()}
                      </strong>
                      <a className="comment" href="/">
                        <i className="fa fa-eye"></i> {views} views
                      </a>
                      <h2>{title}</h2>
                      <p>{description}</p>
                    </div>
                  </div>
                </div>
              )
            )
          )}

          {/* {data.news.home.map(({ image, date, view, title, discription }) => (
            <div className="col-sm-6">
              <div className="col-sm-6 imsec">
                <img className="shado-1" alt="" src={image} />
              </div>
              <div className="col-sm-6 nws">
                <div className="topp">
                  <strong className="mnt">
                    <i className="fa fa-calendar"></i>
                    {date}
                  </strong>
                  <a className="comment" href="/">
                    <i className="fa fa-eye"></i> {view}
                  </a>
                  <h2>{title}</h2>
                  <p>{discription}</p>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
};

export default LatestNews;
