import React from "react";
import data from "../data/data.json";
import CButton from "./CButton";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import Header from "./Header";

const SupportUs = () => {
  return (
    <>
    <Header />
    <div className="home-cont">
      <div className="container-fluid sldddd">
        <div className=" row hidden-xs">
          <div
            style={{ height: "500px", marginTop: "0" }}
            className="slider img-back1"
          ></div>
        </div>

        <div className="container">
          <div className="row hed">
            <h2>Support Us</h2>
          </div>

          <div className="row team">
            {data.support.post.map(({ images, title, discription }) => (
              <div style={{ marginBottom: "30px" }} className="col-sm-4">
                <div className="card box">
                  <img alt="" src={images} />
                  <div className="box-txt">
                    <b>{title}</b>

                    <p>{discription}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default SupportUs;
