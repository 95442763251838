import { Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { toast } from "react-toastify";

const Volunteer = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobile: "",
    aadhaar: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    createdAt: Timestamp.now().toDate(),
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { name, email, mobile, aadhaar, address, city, state, pincode } =
      user;

    if (
      name &&
      email &&
      mobile &&
      aadhaar &&
      address &&
      city &&
      state &&
      pincode
    ) {
      const res = await fetch(
        "https://khidmat-foundation-default-rtdb.firebaseio.com/joining.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            mobile,
            aadhaar,
            address,
            city,
            state,
            pincode,
            createdAt: Timestamp.now().toDate(),
          }),
        }
      );

      if (res) {
        setUser({
          name: "",
          email: "",
          mobile: "",
          aadhaar: "",
          address: "",
          city: "",
          state: "",
          pincode: "",
          createdAt: "",
        });

        toast.success("Volunteer Registration Successfully.");
      }
    } else {
      toast.error("Required all fields !");
    }
  };
  return (
    <>
      <div className="col-md-5 volunteer pl-md-5 ftco-animate fadeInUp ftco-animated text-center">
        <h3 className="mb-3">Volunteer Registration</h3>
        <form method="POST" className="volunter-form">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Full Name"
              onChange={getUserData}
              value={user.name}
              name="name"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Your Email "
              onChange={getUserData}
              value={user.email}
              name="email"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Mobile Number"
              onChange={getUserData}
              value={user.mobile}
              name="mobile"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Aadhaar Number"
              onChange={getUserData}
              value={user.aadhaar}
              name="aadhaar"
            />
          </div>
          <div className="form-group">
            <textarea
              id=""
              cols="30"
              rows="3"
              className="form-control"
              placeholder="Address"
              onChange={getUserData}
              value={user.address}
              name="address"
            ></textarea>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="City "
              onChange={getUserData}
              value={user.city}
              name="city"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="State "
              onChange={getUserData}
              value={user.state}
              name="state"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              placeholder="Pincode"
              onChange={getUserData}
              value={user.pincode}
              name="pincode"
            />
          </div>

          <div className="form-group">
            <input
              type="submit"
              value="Joining Us"
              className="btn btn-danger py-3 px-5"
              onClick={postData}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Volunteer;
