import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Clinic = () => {
  const [data, setData] = useState({});

  const GET = async () => {
    await fetch(
      "https://khidmat-foundation-default-rtdb.firebaseio.com/Patient_Info.json",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        setData(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function Delete(id) {
    await fetch(
      `https://khidmat-foundation-default-rtdb.firebaseio.com/Patient_Info/${id}.json`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((responseJson) => {
        GET();
        toast.success("Record Deleted!");
        // console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function Update(id) {
    alert(id);
    // await fetch(
    //   `https://khidmat-foundation-default-rtdb.firebaseio.com/Patient_Info/${id}.json`,
    //   {
    //     method: "DELETE",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((responseJson) => {
    //     // console.log(responseJson);
    //     GET();
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  useEffect(() => {
    GET();
  }, []);

  return (
    <div className="ml">
      <h4
        className="text-center bg-primary"
        style={{ margin: 0, padding: 10 }}
      >
        All Clinic Patient Details
      </h4>
      <div className="container-fluid my-5 admin-nav">
        <table className="table table-striped">
          <thead className="bg-info text-white ">
            <tr className="">
              <th scope="col">Sr.</th>
              <th scope="col">Name</th>
              <th scope="col">Mobile</th>
              <th scope="col">Address</th>
              {/* <th scope="col">Date & Time</th> */}
              <th scope="col">Doctor</th>
              <th scope="col">Amount</th>
              <th scope="col">Branch</th>
              {/* <th scope="col">WhatsApp</th> */}
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((id, index) => {
              // console.log(id);
              const message =
                "Hello " + data[id].name + ", Wellcome to Khidmat Foundation";
              return (
                <tr key={id} className="">
                  <th scope="row">{index + 1}</th>
                  <td>{data[id].name}</td>
                  <td>{data[id].phone}</td>
                  <td>{data[id].address}</td>
                  {/* <td>{data[id].datetime.split("T").join(",  ")}</td> */}
                  <td>{data[id].doctors}</td>
                  <td>{data[id].amount}/-</td>
                  <td>{data[id].branches}</td>
                  <td>
                    <button className="btn-success">
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        target="_blank"
                        rel="noreferrer"
                        href={`https://api.whatsapp.com/send/?phone=%2B91${data[id].phone}&text=${message}&type=phone_number&app_absent=0`}
                      >
                        <i className="fa fa-whatsapp"></i>
                      </a>
                    </button>

                    {/* <button
                      className="btn-primary"
                      onClick={() => {
                        Update(id);
                      }}
                    >
                      <i className="fa fa-pencil"></i>
                    </button> */}

                    <button
                      className="btn-danger"
                      onClick={() => {
                        Delete(id);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Clinic;
