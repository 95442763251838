import React from 'react'
import Sidebar from '../../adminPanel/Sidebar'

const Appointment = () => {
  return (
    <>
    <Sidebar />
      <div className="ml" style={{ marginLeft: "10%", backgroundColor: "#f7f7ff" }}>
        <h4
          className="text-center text-success"
          style={{ margin: 0, paddingTop: "10px" }}
        >
          Hospital Appointment 
        </h4>
      <iframe
        src="https://sunnimohallaclinic.com/awd"
        width="100%"
        height="748vh"
        frameborder="0"
        title="appointment"
        scrolling="no"
      >
        Loading…
      </iframe>
      </div>
    </>
  )
}

export default Appointment