import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MobileMenus from "../components/MobileMenus";
import { useUserAuth } from "../context/UserAuthContextProvider";

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { logIn } = useUserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/admin-login");
    } catch (err) {
      setError(err.message);
      toast(`Try again ! ${error && error}`, { type: "error" });
    }
  };

  return (
    <>
      <div
        style={{ zIndex: "99999", marginBottom: "70px" }}
        className="container-fluid"
      >
        <MobileMenus />
      </div>

      <div className="container">
        <div className="row hed">
          <h2>Admin Login</h2>
        </div>

        <div className="col-md-4">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-10 col-sm-10">
                <i className="fa fa-user"></i> Username
                <input
                  className="form-control input-sm"
                  placeholder="Username"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
              </div>

              <div className="col-md-10 col-sm-10">
                <i className="fa fa-lock"></i> Password
                <input
                  className="form-control input-sm"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <br />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control btn-success"
                  type="submit"
                  value="Admin Login "
                />
                <br />
              </div>
            </div>
          </form>
          {error && <p className="text-danger">{error}</p>}
        </div>
      <Link to="/admin-login">Direct Login</Link>
      </div>
      <br />
      <br />
    </>
  );
};

export default Admin;
