import React from "react";
import data from "../data/data.json";

const Slider = () => {
  return (
    <>
      <div className=" row">
        <div className="sli">
          <div
            id="carousel-example-generic"
            className="carousel slide"
            data-ride="carousel"
          >
            {/* <!-- Indicators --> */}
            <ol className="carousel-indicators hidden-xs hidden-sm">
              <li
                data-target="#carousel-example-generic"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carousel-example-generic"
                data-slide-to="2"
              ></li>
            </ol>

            {/* <!-- Wrapper for slides --> */}

            <div className="carousel-inner" role="listbox">
              <div className="item">
                <img src={data.banner.slider[0]} alt="..." />
                <div className="carousel-caption hidden-xs hidden-sm">
                  <h2 className="fevf">Khidmat Education Library</h2>
                  <h2 className="fevf">
                    We provide Eduction to poor people & Donate books to needy
                    Students{" "}
                  </h2>
                  <strong className="ssstitle">
                    Join with us to support them.
                  </strong>
                </div>
              </div>

              <div className="item">
                <img src={data.banner.slider[1]} alt="..." />
                <div className="carousel-caption hidden-xs hidden-sm">
                  <h2 className="fevf">Sunni Mohalla Clinic</h2>
                  <h2 className="fevf">
                    Our organization provide Health Care Center for poor people
                    only in 20/- fees{" "}
                  </h2>
                  <strong className="ssstitle">
                    Visit our new Hospital Website.
                  </strong>
                </div>
              </div>

              <div className="item active">
                <img src={data.banner.slider[2]} alt="..." />
                <div className="carousel-caption hidden-xs hidden-sm">
                  <h2 className="fevf">Langar-e-Chistiya</h2>
                  <h2 className="fevf">
                    We provide Rashan Kit, Clothes & give money to needy ones{" "}
                  </h2>
                  <strong className="ssstitle">
                    Help us to gave monthly foods.
                  </strong>
                </div>
              </div>
            </div>

            {/* <!-- Controls --> */}
            <a
              className="left carousel-control hidden-xs hidden-sm"
              href="#carousel-example-generic"
              role="button"
              data-slide="prev"
            >
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control hidden-xs hidden-sm"
              href="#carousel-example-generic"
              role="button"
              data-slide="next"
            >
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
