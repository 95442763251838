import { Timestamp } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import data from "../data/data.json";
import CButton from "./CButton";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import Header from "./Header";

const ContactUs = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    createdAt: Timestamp.now().toDate(),
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { name, email, mobile, message } = user;

    if (name && email && mobile && message) {
      const res = await fetch(
        "https://khidmat-foundation-default-rtdb.firebaseio.com/contact-us.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            mobile,
            message,
            createdAt: Timestamp.now().toDate(),
          }),
        }
      );

      if (res) {
        setUser({
          name: "",
          email: "",
          mobile: "",
          message: "",
          createdAt: "",
        });
        toast.success("Message Send Successfully.");
      }
    } else {
      toast.error("Required all fields !");
    }
  };
  return (
    <>
      <Header />
      <div className="home-cont">
        <div className="container-fluid sldddd">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.8442839899744!2d79.14347701488494!3d21.118773385949705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4b971b1844705%3A0xe969f2cf88421401!2sKhidmat%20Foundation%20Nagpur!5e0!3m2!1sen!2sin!4v1636807154400!5m2!1sen!2sin"
              height="450"
              frameBorder="0"
              style={{ border: "0", width: "100%" }}
              allowFullScreen=""
              title="map"
              loading="lazy"
            ></iframe>
          </div>

          <div className="container">
            <div className="col-sm-4">
              <div className="row">
                <div className="row hed">
                  <h2>Contact Us</h2>
                </div>

                <form action="" method="post">
                  <div
                    style={{
                      height: "370px",
                      minWidth: "300px",
                      padding: "5px 0 0 0 ",
                    }}
                    className="filter shado-2 inner-cint"
                  >
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-user"></i>
                          </td>
                          <td>
                            <input
                              className="form-control input-sm"
                              placeholder="Name"
                              type="text"
                              onChange={getUserData}
                              value={user.name}
                              name="name"
                              autoComplete="off"
                              required
                            />{" "}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <i className="fa fa-envelope"></i>
                          </td>
                          <td>
                            <input
                              className="form-control input-sm"
                              onChange={getUserData}
                              value={user.email}
                              type="email"
                              name="email"
                              placeholder="Email"
                              autoComplete="off"
                              required
                            />{" "}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <i className="fa fa-phone"></i>
                          </td>
                          <td>
                            <input
                              className="form-control input-sm"
                              placeholder="Mobile"
                              onChange={getUserData}
                              value={user.mobile}
                              type="number"
                              name="mobile"
                              autoComplete="off"
                              required
                            />{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-send"></i>
                          </td>
                          <td>
                            <textarea
                              style={{ height: "60%" }}
                              className="form-control ctxtar"
                              placeholder="Your Message"
                              onChange={getUserData}
                              value={user.message}
                              rows="3"
                              autoComplete="off"
                              type="text"
                              name="message"
                              required
                            ></textarea>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <button
                              className="btn btn-success cust"
                              style={{ width: "100%" }}
                              type="submit"
                              onClick={postData}
                            >
                              Send
                            </button>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>

            {data.contact.address.map(
              ({ title, address, mobile1, mobile2 }) => (
                <div
                  style={{ padding: "10%" }}
                  className="col-sm-4 col-xs-12 addr"
                >
                  <b>{title}</b> <br />
                  <p style={{ fontSize: "15px" }}>
                    <br />
                    {address}
                    <br /> <br />
                    <i className="fa fa-phone-square"></i> {mobile1} <br />
                    <i className="fa fa-phone-square"></i> {mobile2} <br />
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default ContactUs;
