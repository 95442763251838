import React from "react";
import data from "../data/data.json";
import CButton from "./CButton";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import Header from "./Header";

const Education = () => {
  return (
    <>
      <Header />
      <div className="home-cont">
        <div className="container-fluid sldddd">
          <div style={{ marginTop: "15px" }} className="container tmon">
            <div className="row hed">
              <h2>Education</h2>
            </div>

            <div className="col-sm-9">
              {data.education.post.map(({ images, title, discription }) => (
                <div style={{ marginTop: "20px" }} className="row">
                  <div className="col-sm-3">
                    <img alt="" src={images} />
                  </div>
                  <div
                    style={{ marginTop: "10px" }}
                    className="col-sm-9 m-auto"
                  >
                    <b>{title}</b>
                    <p>{discription}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-sm-3">
              <img
                style={{ widows: "100%" }}
                alt=""
                src="assets/images/1082059559.jpeg"
              />
            </div>
          </div>
        </div>
      </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default Education;
