import AdminNav from "../admin/AdminNav";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fireDb from "../../../firebase";

const initialState = {
  name: "",
  guardiansname: "",
  gender: "",
  dob: "",
  weight: "",
  address: "",
  bloodgroup: "",
  email: "",
  contact: "",
  username: "",
  password: "",
  createdAt: Timestamp.now().toDate(),
};

const Donor = () => {
  const [IsLoading, setIsLoading] = useState(false);

  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const {
    name,
    guardiansname,
    gender,
    dob,
    weight,
    address,
    bloodgroup,
    email,
    contact,
    username,
    password,
  } = state;

  const history = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("BloodSystem/AddDonor").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !name ||
      !guardiansname ||
      !gender ||
      !dob ||
      !weight ||
      !address ||
      !bloodgroup ||
      !email ||
      !contact ||
      !username ||
      !password
    ) {
      toast.error("Please fill all input field !");
    } else {
      if (!id) {
        fireDb.child("BloodSystem/AddDonor").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Donor Added Success");
          }
        });
      } else {
        fireDb.child(`BloodSystem/AddDonor/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Donor Updated Success");
          }
        });
      }
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      setTimeout(() => history("/view-donor"), 500);
    }
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="orange"
            height={100}
            width={50}
          />
        </div>
      ) : (
        <div id="wrapper">
          <AdminNav title={id ? "Update Donor" : "Add Donor's"} icon={id ? "fa fa-edit fa-fw" : "fa fa-user-plus fa-fw"} />
          <div id="page-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="page-header"> {id ? "Update Donor" : "Add Donor's"} Detail</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      Please fill up the form below:
                    </div>
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <form method="post" onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label>Full Name</label>
                              <input
                                value={name || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                name="name"
                                type="text"
                                placeholder="Enter Name"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Guardian's Name</label>
                              <input
                                value={guardiansname || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Guardian's Name"
                                name="guardiansname"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Gender [ M/F ]</label>
                              <input
                                value={gender || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="M or F"
                                name="gender"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Date of Birth</label>
                              <input
                                value={dob || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                type="date"
                                name="dob"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Weight</label>
                              <input
                                value={weight || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                type="number"
                                placeholder="Enter Weight"
                                name="weight"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Blood Group</label>
                              <input
                                value={bloodgroup || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Eg: B+"
                                name="bloodgroup"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Email Id</label>
                              <input
                                value={email || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                type="email"
                                placeholder="Enter Email Id"
                                name="email"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Address</label>
                              <input
                                value={address || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                type="text"
                                placeholder="Enter Address Here"
                                name="address"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Contact Number</label>
                              <input
                                value={contact || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                type="number"
                                placeholder="Contact Number"
                                name="contact"
                                required
                              />
                            </div>

                            <div className="form-group">
                              <label>Username</label>
                              <input
                                value={username || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Enter Here"
                                name="username"
                                required
                              />
                              <p className="help-block">
                                In order to create donor's account.
                              </p>
                              <p className="help-block">
                                Example: sarfaraz2022
                              </p>
                            </div>

                            <div className="form-group">
                              <label>Password</label>
                              <input
                                value={password || ""}
                                onChange={handleInputChange}
                                className="form-control"
                                name="password"
                                type="password"
                                id="myInput"
                                required
                              />
                              <div className="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    onclick="myFunction()"
                                  />
                                  Show Password
                                </label>
                              </div>
                            </div>

                            <input
                              className="btn btn-success"
                              style={{ borderRadius: "0%" }}
                              type="submit"
                              value={id ? "Update" : "Save"}
                            />
                            {/* <button
                              onClick={postData}
                              type="submit"
                              className="btn btn-success"
                              style={{ borderRadius: "0%" }}
                            >
                              Submit Form
                            </button> */}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Donor;
