import React from "react";
import { Link } from "react-router-dom";
import AdminNav from "./AdminNav";
import { useEffect, useState } from "react";
import fireDb from "../../../firebase";
import { toast } from "react-toastify";

const ViewCampaign = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("BloodSystem/Campaign").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const onDelete = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete this Campaign ?")
    ) {
      fireDb.child(`BloodSystem/Campaign/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Campaign Deleted !");
        }
      });
    }
  };

  return (
    <div id="wrapper">
      <AdminNav title="Campaign Details" icon="fa fa-flag fa-fw" />
      <div id="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className=".col-lg-12">
              <h1 className="page-header">Campaign Details</h1>
            </div>
          </div>

          <div className="row">
            <div className=".col-lg-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  Campaign Details
                  <Link to="/new-campaign" className="addBtns">
                    New Campaign
                  </Link>
                </div>

                <div className="panel-body">
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-bordered table-hover"
                      id="dataTables-example"
                    >
                      <thead style={{ whiteSpace: "nowrap" }}>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Campaign Name</th>
                          <th>Organizer Name</th>
                          <th>Phone Number</th>
                          <th>Date of campaign</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(data).map((id, index) => {
                          return (
                            <tr key={id}>
                              <td>{index + 1}</td>
                              <td>{data[id].campaign}</td>
                              <td>{data[id].organizer}</td>
                              <td>{data[id].number}</td>
                              <td>{data[id].date}</td>
                              <td>{data[id].description}</td>
                              <td
                                style={{
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                              >
                                <Link to={`/view/${id}`}>
                                  <i
                                    className="fa fa-eye"
                                    style={{
                                      color: "blue",
                                      marginRight: "10px",
                                    }}
                                  ></i>
                                </Link>
                                <Link to={`/campaigns-update/${id}`}>
                                  <i
                                    className="fa fa-edit"
                                    style={{
                                      color: "green",
                                      marginRight: "10px",
                                    }}
                                  ></i>
                                </Link>

                                <i
                                  onClick={() => onDelete(id)}
                                  className="fa fa-trash"
                                  style={{ color: "red" }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCampaign;
