import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../adminPanel/Sidebar";
import MobileMenus from "../components/MobileMenus";
import { UserLogout } from "./UserLogout";

export const AdminLogin = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "10%" }}>
      <div
        style={{ zIndex: "99999", marginBottom: "10px" }}
        className="container-fluid"
      >
        {/* <MobileMenus /> */}
      </div>
      
      <div className="container">
        <UserLogout />
        <div class="row">
          <div class="col-lg-2 col-xs-6 bg-warning home-admin">
            <Link to="dashbord">
              <div class="card-body text-danger">
                <h1 class="card-title text-center p-4">
                  <i class="fa fa-tachometer"></i>
                </h1>
                <p class="card-text text-center">
                  <b> Dashbord </b>
                </p>
              </div>
            </Link>
          </div>
          <div class="col-lg-2 col-xs-6 bg-warning home-admin">
            <Link to="database">
              <div class="card-body text-danger">
                <h1 class="card-title text-center p-4">
                  <i class="fa fa-database"></i>
                </h1>
                <p class="card-text text-center">
                  <b> Database </b>
                </p>
              </div>
            </Link>
          </div>
          <div class="col-lg-2 col-xs-6 bg-warning home-admin">
            <Link to="hospital">
              <div class="card-body text-danger">
                <h1 class="card-title text-center p-4">
                  <i class="fa fa-hospital-o"></i>
                </h1>
                <p class="card-text text-center">
                  <b> Hospital </b>
                </p>
              </div>
            </Link>
          </div>
          <div class="col-lg-2 col-xs-6 bg-warning home-admin">
            <Link to="notification">
              <div class="card-body text-danger">
                <h1 class="card-title text-center p-4">
                  <i class="fa fa-bell-o"></i>
                </h1>
                <p class="card-text text-center">
                  <b> Notification </b>
                </p>
              </div>
            </Link>
          </div>
          <div class="col-lg-2 col-xs-6 bg-warning home-admin">
            <Link to="users">
              <div class="card-body text-danger">
                <h1 class="card-title text-center p-4">
                  <i class="fa fa-user-plus"></i>
                </h1>
                <p class="card-text text-center">
                  <b> Add Users </b>
                </p>
              </div>
            </Link>
          </div>
          <div class="col-lg-2 col-xs-6 bg-warning home-admin">
            <Link to="c">
              <div class="card-body text-danger">
                <h1 class="card-title text-center p-4">
                  <i class="fa fa-comments"></i>
                </h1>
                <p class="card-text text-center">
                  <b> Live Chats </b>
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      </div>
      <br />
      <br />
    </>
  );
};
