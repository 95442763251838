import React from 'react'
import data from "../data/data.json";
import TopBar from "./TopBar";
import Nav from "./Nav";

const MobileMenus = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  return (
    <>
    <Nav isOpen={isMenuOpen} />
        <div className="mobile-head shado-1 visible-xs">
          <img
            style={{ width: "50px" }}
            className="logo"
            alt=""
            src={data.logo}
          />
          <h1 style={{ marginLeft: "4px" }}>Khidmat Foundation</h1>
          <b style={{ marginLeft: " px" }}>Nagpur Charity Organization</b>
          <TopBar setMenuStatus={setIsMenuOpen} />
        </div>
    </>
  )
}

export default MobileMenus