import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import DeleteAllBlogs from "./DeleteAllBlogs";

const AllBlogsView = () => {
  const [BlogsAll, setBlogsAll] = useState([]);
  useEffect(() => {
    const BlogsAllRef = collection(db, "BlogsAll");
    const q = query(BlogsAllRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const BlogsAll = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogsAll(BlogsAll);
      console.log(BlogsAll);
    });
  }, []);
  return (
    <>
      <div className="row hed">
        <h2>All Blogs View</h2>
      </div>

      {BlogsAll.length === 0 ? (
        <p className="p-5">Try again later Error found 404 ! </p>
      ) : (
        BlogsAll.map(
          ({ id, title, description, image, comment, createdAt }) => (
            <div className="col-sm-12" style={{marginBottom: "30px"}} key={id}>
              <div className="col-sm-4 imsec">
                <img className="shado-1" alt="" src={image} />
              </div>
              <div className="col-sm-6 nws">
                <div className="topp">
                  <strong className="mnt">
                    <i className="fa fa-calendar"></i>
                    {createdAt.toDate().toDateString()}
                  </strong>
                  <a className="comment" href="/">
                    <i className="fa fa-comments-o"></i> {comment} Comment
                  </a>
                  <h2>{title}</h2>
                  <p>{description}</p>
                  <DeleteAllBlogs id={id} image={image} />
                </div>
              </div>
            </div>
          )
        )
      )}
    </>
    
  )
}

export default AllBlogsView