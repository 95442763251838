import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminNav from "./AdminNav";
import fireDb from "../../../firebase";
import { toast } from "react-toastify";

const ViewBlood = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("BloodSystem/AddBlood").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const onDelete = (id) => {
    if (window.confirm("Are you sure that you wanted to delete this Blood ?")) {
      fireDb.child(`BloodSystem/AddBlood/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Blood Deleted !");
        }
      });
    }
  };

  return (
    <>
      <div id="wrapper">
        <AdminNav
          title="Blood Collection Details"
          icon="fa fa-heartbeat fa-fw"
        />
        <div id="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className=".col-lg-12">
                <h1 className="page-header">Blood Collection</h1>
              </div>
            </div>

            <div className="row">
              <div className=".col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Blood Details
                    <Link to="/add-blood" className="addBtns">
                      Add More Blood
                    </Link>
                  </div>

                  <div className="panel-body">
                    <div className="table-responsive">
                      <table
                        className="table table-striped table-bordered table-hover"
                        id="dataTables-example"
                      >
                        <thead style={{ whiteSpace: "nowrap" }}>
                          <tr>
                            <th>Blood No.</th>
                            <th>Blood Group</th>
                            <th>Full Name</th>
                            <th>Gender</th>
                            <th>D.O.B</th>
                            <th>Weight</th>
                            <th>Address</th>
                            <th>Contact</th>
                            <th>Blood Quantity</th>
                            <th>Collection Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(data).map((id, index) => {
                            return (
                              <tr className="gradeA" key={id}>
                                <td>0{index + 1}</td>
                                <td>{data[id].bloodgroup}</td>
                                <td>{data[id].name}</td>
                                <td>{data[id].gender}</td>
                                <td>{data[id].dob}</td>
                                <td>{data[id].weight}</td>
                                <td>{data[id].address}</td>
                                <td>{data[id].contact}</td>
                                <td>{data[id].bloodqty}</td>
                                <td>{data[id].collection}</td>
                                <td
                                  style={{
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                  }}
                                >
                                  {/* <Link to={`/view/${id}`}> */}
                                  <i
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    className="fa fa-eye"
                                    style={{
                                      color: "blue",
                                      marginRight: "10px",
                                    }}
                                  ></i>
                                  {/* </Link> */}
                                  <Link to={`/blood-update/${id}`}>
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        color: "green",
                                        marginRight: "10px",
                                      }}
                                    ></i>
                                  </Link>

                                  <i
                                    onClick={() => onDelete(id)}
                                    className="fa fa-trash"
                                    style={{ color: "red" }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBlood;
