import React from 'react'
import AllBlogsAdd from "./AllBlogsAdd";
import AllBlogsView from "./All-Blogs";

const AllBlogs = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <AllBlogsView />
          </div>
          <div className="col-md-3">
            <AllBlogsAdd />
          </div>
        </div>
      </div>
    </>
  )
}

export default AllBlogs
