import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../adminPanel/Sidebar";
import MobileMenus from "../components/MobileMenus";
import { UserLogout } from "./UserLogout";

export const Database = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "10%" }}>
      <div
        style={{ zIndex: "99999", marginBottom: "10px" }}
        className="container-fluid"
      >
        {/* <MobileMenus /> */}
      </div>

      <div className="container pb-5">
      <UserLogout />
        <h5>WELCOME TO DATABASE</h5>
        <div className="row">
          <div className="col-lg-3 col-xs-4 pb-4 ps-2">
            <Link to="contactview">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-book"></i>
                </h1>
                <p className="card-text text-center">Contact Book</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-xs-4 pb-4 ps-2">
            <Link to="dmview">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-envelope"></i>
                </h1>
                <p className="card-text text-center">Messages</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-xs-4 pb-4 ps-2">
            <Link to="volunteerview">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-users"></i>
                </h1>
                <p className="card-text text-center">Volunteers</p>
              </div>
            </Link>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
      </div>
    </>
  );
};
