import React from "react";
import Sidebar from "../adminPanel/Sidebar";
import { GalleryAdd } from "./GalleryAdd";
import { GalleryView } from "./GalleryView";

export const AddGallery = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "100px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <GalleryView />
          </div>
          <div className="col-md-3">
            <GalleryAdd />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
