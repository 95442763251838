import data from "../data/data.json";
import ImagesGallery from "../data/images.js";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import Header from "./Header";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import CButton from "./CButton";

const Gallery = () => {
  const [GalleryView, setGalleryView] = useState([]);
  useEffect(() => {
    const GalleryViewRef = collection(db, "Gallery");
    const q = query(GalleryViewRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const GalleryView = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGalleryView(GalleryView);
    });
  }, []);
  return (
    <>
      <Header />
      <div className="home-cont">
        <div className="container-fluid sldddd">
          <div className=" row"></div>

          <div style={{ marginTop: "15px" }} className="container gall">
            <div className="row hed">
              <h2>Gallery</h2>
            </div>

            <ImagesGallery />

            <center>
              <div className="container pt-5 mt-0 pb-0 mb-0">
                <div className="row featurette d-flex justify-content-center align-items-center">
                  {GalleryView.length === 0 ? (
                    <div className="p-5">
                      {data.gallery.image.map((images) => (
                        <div className="col-md-4 pb-4 mb-3">
                          <img
                            className="img-thumbnail"
                            src={images}
                            alt="images"
                            width="500"
                            height="500"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    GalleryView.map(({ id, image }) => (
                      <div className="col-md-4 pb-4 mb-3" key={id}>
                        <img
                          className="img-thumbnail"
                          src={image}
                          alt="images"
                          width="500"
                          height="500"
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default Gallery;
