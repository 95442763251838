import AdminNav from "../admin/AdminNav";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLinkClickHandler, useParams } from "react-router-dom";
import fireDb from "../../../firebase";

const initialState = {
  campaign: "",
  organizer: "",
  number: "",
  date: "",
  description: "",
};

const Campaign = () => {
  const [IsLoading, setIsLoading] = useState(false);

  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { campaign, organizer, number, date, description } = state;

  const history = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("BloodSystem/Campaign").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!campaign || !organizer || !number || !date || !description) {
      toast.error("Please fill all input field !");
    } else {
      if (!id) {
        fireDb.child("BloodSystem/Campaign").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Campaign Add Success");
          }
        });
      } else {
        fireDb.child(`BloodSystem/Campaign/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Campaign Updated Success");
          }
        });
      }
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      setTimeout(() => history("/views-campaigns"), 500);
    }
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="orange"
            height={100}
            width={50}
          />
        </div>
      ) : (
        <div id="wrapper">
          <AdminNav title="Campaign" icon="fa fa-flag fa-fw" />
          <div id="page-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="page-header">
                  {id ? "Update" : "Start New"} Campaign
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Please fill up the form below:
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <form method="post" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Campaign Name</label>
                            <input
                              value={campaign || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="text"
                              placeholder="Enter Campaign's Name"
                              name="campaign"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label>Organizer Name</label>
                            <input
                              value={organizer || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="text"
                              placeholder="Enter Organizer's Name"
                              name="organizer"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Contact Number</label>
                            <input
                              value={number || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Your Number"
                              type="number"
                              name="number"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Campaign Date</label>
                            <input
                              value={date || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="date"
                              name="date"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Description</label>
                            <textarea
                              value={description || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="text"
                              rows="4"
                              name="description"
                              required
                            ></textarea>
                          </div>

                          <input
                            className="btn btn-success"
                            style={{ borderRadius: "0%" }}
                            type="submit"
                            value={id ? "Update" : "Save"}
                          />
                          {/* <button
                            onClick={postData}
                            type="submit"
                            href=""
                            className="btn btn-success"
                            style={{ borderRadius: "0%" }}
                          >
                            Post
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Campaign;
