import React from "react";
import Slider from "./Slider.js";
import Action from "./Action.js";
import LatestNews from "./LatestNews.js";
import LatestEvents from "./LatestEvents.js";
import BlogsPost from "./BlogsPost.js";
import Videos from "./Videos.js";
import Header from "./Header";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import CButton from "./CButton";
// import Joining from "./Joining.js";

const Home = () => {
  return (
    <>
      <Header />
      <div ng-controller="homeCtrl" className="home-cont">
        <div className="container-fluid sldddd">
          <Slider />
          <Action />
          <LatestNews />
          <LatestEvents />
        </div>
        <BlogsPost />
        <Videos />
      </div>
      {/* <Joining /> */}
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default Home;
