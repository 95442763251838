import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../adminPanel/Sidebar";
import MobileMenus from "../components/MobileMenus";
import { UserLogout } from "./UserLogout";

export const Dashbord = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "10%" }}>
      <div
        style={{ zIndex: "99999", marginBottom: "10px" }}
        className="container-fluid"
      >
        {/* <MobileMenus /> */}
      </div>
      
      <div className="container">
      <UserLogout />
        <div className="">
          <h5>WELCOME TO DASHBORD</h5>
        </div>
        <div className="row">
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="news">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-newspaper-o"></i>
                </h1>
                <p className="card-text text-center">News</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="events">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-calendar"></i>
                </h1>
                <p className="card-text text-center">Events</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="blogs">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-list-alt"></i>
                </h1>
                <p className="card-text text-center">Blogs</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="gallery">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-picture-o"></i>
                </h1>
                <p className="card-text text-center">Gallery</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="videos">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-youtube-play"></i>
                </h1>
                <p className="card-text text-center">Videos</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="registration">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-wrench"></i>
                </h1>
                <p className="card-text text-center">Setting</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      </div>
      <br /><br /><br />
    </>
  );
};
