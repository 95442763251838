import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./userlogin.scss";
// import ReactLoading from "react-loading";

const UsersLogin = () => {
  const [bootstrap] = useState(
    "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
  );
  const [fontawesome1] = useState(
    "https://use.fontawesome.com/releases/v5.6.1/css/all.css"
  );
  const [fontawesome2] = useState(
    "https://use.fontawesome.com/releases/v5.7.1/css/all.css"
  );
  const [jquery] = useState(
    "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"
  );
  // const [IsLoading, setIsLoading] = useState(false);
  // const Loader = () => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // };

  return (
    <>
      <link rel="stylesheet" type="text/css" href={bootstrap} />
      <link rel="stylesheet" type="text/css" href={fontawesome1} />
      <link rel="stylesheet" type="text/css" href={fontawesome2} />
      <script src={jquery}></script>

      {/* {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="#d9534f"
            height={100}
            width={50}
          />
        </div>
      ) : ( */}
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="user_card">
              <div className="d-flex justify-content-center">
                <div className="brand_logo_container">
                  <img
                    src="http://localhost/php_project/userlog/img/ico.png"
                    className="brand_logo"
                    alt="Logo"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center form_container">
                <form action="/user-dashboard" method="get">
                  <div className="input-group mb-3">
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      name="username"
                      className="form-control input_user"
                      // value=""
                      placeholder="Username"
                      required
                    />
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fas fa-key"></i>
                      </span>
                    </div>
                    <input
                      type="password"
                      name="password"
                      className="form-control input_pass"
                      // value=""
                      placeholder="Password"
                      required
                    />
                  </div>

                  <div className="d-flex justify-content-center mt-3 login_container"
                      // onClick={Loader}
                   >
                    <button
                      type="submit"
                      name="userlogin"
                      style={{ borderRadius: "0%" }}
                      className="btn login_btn"
                    >
                      User Login
                    </button>
                  </div>
                </form>
              </div>

              <div className="mt-4">
                {/* <div className="d-flex justify-content-center links">
                Don't have an account?{" "}
                <Link to="#" className="ml-2" style={{textDecoration:"none"}}>
                  Sign Up
                </Link>
              </div> */}
                <div className="d-flex justify-content-center links">
                  <Link
                    to="/admin-login"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Back to Admin Panel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
};

export default UsersLogin;
