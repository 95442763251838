import React from "react";
import { Link } from "react-router-dom";
import AdminNav from "./AdminNav";
import { useEffect, useState } from "react";
import fireDb from "../../../firebase";
import { toast } from "react-toastify";

const ViewAnnouncement = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("BloodSystem/Announcement").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const onDelete = (id) => {
    if (
      window.confirm(
        "Are you sure that you wanted to delete this Announcement ?"
      )
    ) {
      fireDb.child(`BloodSystem/Announcement/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Announcement Deleted !");
        }
      });
    }
  };

  return (
    <div id="wrapper">
      <AdminNav title="Announcement Details" icon="fa fa-bullhorn fa-fw" />
      <div id="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className=".col-lg-12">
              <h1 className="page-header">Announcement Details</h1>
            </div>
          </div>

          <div className="row">
            <div className=".col-lg-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                Announcement
                  <Link to="/make-announcement" className="addBtns">
                    New Announcement
                  </Link>
                </div>

                <div className="panel-body">
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-bordered table-hover"
                      id="dataTables-example"
                    >
                      <thead style={{ whiteSpace: "nowrap" }}>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Title</th>
                          <th>Blood Needed</th>
                          <th>Announcement Date</th>
                          <th>Organizer</th>
                          <th>Requirements</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(data).map((id, index) => {
                          return (
                            <tr key={id}>
                              <td>{index + 1}</td>
                              <td>{data[id].announcement}</td>
                              <td>{data[id].bloodneed}</td>
                              <td>{data[id].date}</td>
                              <td>{data[id].organizer}</td>
                              <td>{data[id].requirements}</td>
                              <td
                                style={{
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                              >
                                {/* <Link to={`/view/${id}`}> */}
                                <i
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  className="fa fa-eye"
                                  style={{
                                    color: "blue",
                                    marginRight: "10px",
                                  }}
                                ></i>
                                {/* </Link> */}
                                <Link to={`/announcement-update/${id}`}>
                                  <i
                                    className="fa fa-edit"
                                    style={{
                                      color: "green",
                                      marginRight: "10px",
                                    }}
                                  ></i>
                                </Link>

                                <i
                                  onClick={() => onDelete(id)}
                                  className="fa fa-trash"
                                  style={{ color: "red" }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">View Announcement</h4>
              </div>
              <div className="modal-body">
                <h1>View</h1>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAnnouncement;
