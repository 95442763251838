import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Url = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location) {
    console.log(location.pathname, "location");
  } else {
    console.log(navigate.location.pathname, "history");
  }

  if (navigate) {
    console.log(window.location.href, "history");
  } else {
    console.log(navigate.location.pathname, "history");
  }

  return (
    <div style={{ background: "black", color: "green" }}>
      <h1>{location ? location.pathname : "na"} location</h1>
      <h1>{navigate ? navigate.location.pathname : "na"} navigate</h1>
      <h1>{navigate ? window.location.href : "na"} url</h1>
    </div>
  );
};

export default Url;
