import React from "react";
import BlogsPost from "./Blogs-Post";
import BlogsPostAdd from "./BlogsPost";

const AddBlogs = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <BlogsPost />
          </div>
          <div className="col-md-3">
            <BlogsPostAdd />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogs;
