import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import DonorNav from "./DonorNav";
import ReactLoading from "react-loading";

const UsersDashboard = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const Loader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="#d9534f"
            height={100}
            width={50}
          />
          <DonorNav />
        </div>
      ) : (
        <>
        <DonorNav />
          <div id="wrapper" onClick={Loader}>
            <div id="page-wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="page-header">Donor's Dashboard</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="panel panel-green">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-3">
                          <i className="icofont-blood icofont-5x"></i>
                          {/* <i className="fa fa-heartbeat fa-5x"></i>  */}
                        </div>
                        <div className="col-xs-9 text-right">
                          {/* <!-- in order to count total donor's record --> */}
                          {/* <?php include 'counter/dashbloodcount.php';?>  */}
                          <div className="huge">22</div>
                          <div>Available Blood</div>
                        </div>
                      </div>
                    </div>
                    <Link to="/view-blood">
                      <div className="panel-footer">
                        <span className="pull-left">
                          View Available Blood Details
                        </span>
                        <span className="pull-right">
                          <i className="fa fa-arrow-circle-right"></i>
                        </span>
                        <div className="clearfix"></div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="panel panel-warning">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-3">
                          <i className="fa fa-flag fa-5x"></i>
                        </div>
                        <div className="col-xs-9 text-right">
                          {/* <!-- in order to count total donor's record --> */}
                          {/* <?php include 'counter/dashboardcampaigncount.php';?>  */}

                          <div className="huge">26</div>
                          <div>Campaigns Made</div>
                        </div>
                      </div>
                    </div>

                    <Link to="/view-campaigns">
                      <div className="panel-footer">
                        <span className="pull-left">View Available Campaigns</span>
                        <span className="pull-right">
                          <i className="fa fa-arrow-circle-right"></i>
                        </span>
                        <div className="clearfix"></div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="panel panel-yellow">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-3">
                          <i className="fa fa-bullhorn fa-5x"></i>
                        </div>
                        <div className="col-xs-9 text-right">
                          {/* <?php include 'counter/dashannouncecount.php';?> */}
                          <div className="huge">12</div>
                          <div>Announcement</div>
                        </div>
                      </div>
                    </div>
                    <Link to="/view-announcement">
                      <div className="panel-footer">
                        <span className="pull-left">View Announcement Details</span>
                        <span className="pull-right">
                          <i className="fa fa-arrow-circle-right"></i>
                        </span>
                        <div className="clearfix"></div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="panel panel-red">
                    <div className="panel-heading">
                      <div className="row">
                        <div className="col-xs-3">
                          <i className="icofont-blood-drop icofont-5x"></i>
                        </div>
                        <div className="col-xs-9 text-right">
                          <div className="huge">Donate</div>
                          <div>Blood</div>
                        </div>
                      </div>
                    </div>
                    <Link to="/UserAddBlood">
                      <div className="panel-footer">
                        <span className="pull-left">Donate Blood Now!</span>
                        <span className="pull-right">
                          <i className="fa fa-arrow-circle-right"></i>
                        </span>
                        <div className="clearfix"></div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div
                    className="alert alert-danger alert-dismissible"
                    role="alert"
                  >
                    <button
                      type="button"
                      onclick="this.parentNode.parentNode.removeChild(this.parentNode);"
                      className="close"
                      data-dismiss="alert"
                    >
                      <span aria-hidden="true">×</span>
                      <span className="sr-only">Close</span>
                    </button>
                    <strong>
                      <i className="fa fa-warning"></i> You Are Currently Viewing
                      User's Account
                    </strong>
                    {/* <marquee>
                      <p style={{ fontFamily: "Impact", fontSize: "18pt" }}>
                        You Are Currently Viewing User's Account
                      </p>
                    </marquee> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <Footer /> */}
    </>
  );
};

export default UsersDashboard;
