import React from "react";
import ImageGallery from "react-image-gallery";
// # SCSS
// import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
// # CSS
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";

const ImagesGallery = () => {
  const images = [
    {
      original: "http://khidmat-foundation.com/images/4.jpg",
      thumbnail: "http://khidmat-foundation.com/images/4.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/a.jpg",
      thumbnail: "http://khidmat-foundation.com/images/a.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/b.jpg",
      thumbnail: "http://khidmat-foundation.com/images/b.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/c.jpg",
      thumbnail: "http://khidmat-foundation.com/images/c.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/d.jpg",
      thumbnail: "http://khidmat-foundation.com/images/d.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/e.jpg",
      thumbnail: "http://khidmat-foundation.com/images/e.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/f.jpg",
      thumbnail: "http://khidmat-foundation.com/images/f.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/g.jpg",
      thumbnail: "http://khidmat-foundation.com/images/g.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/h.jpg",
      thumbnail: "http://khidmat-foundation.com/images/h.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/j.jpg",
      thumbnail: "http://khidmat-foundation.com/images/j.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/k.jpg",
      thumbnail: "http://khidmat-foundation.com/images/k.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/l.jpg",
      thumbnail: "http://khidmat-foundation.com/images/l.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/m.jpg",
      thumbnail: "http://khidmat-foundation.com/images/m.jpg",
    },
    {
      original: "http://khidmat-foundation.com/images/n.jpg",
      thumbnail: "http://khidmat-foundation.com/images/n.jpg",
    },
  ];
  return <ImageGallery items={images} />;
};

export default ImagesGallery;
