import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
// import data from "../data/data.json";
// import DeleteArticle from "./DeleteArticle";

const Videos = () => {
  const [LatestVideo, setLatestVideo] = useState([]);
  useEffect(() => {
    const LatestVideoRef = collection(db, "LatestVideo");
    const q = query(LatestVideoRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const LatestVideo = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLatestVideo(LatestVideo);
      console.log(LatestVideo);
    });
  }, []);
  return (
    <>
      <div className="container video">
        <div className="row hed">
          <h2> Latest Videos</h2>
          <Link to="/videos">
            <h5 style={{ float: "right" }}>
              View All
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
            </h5>
          </Link>
        </div>

        <div className="row">
          {LatestVideo.length === 0 ? (
            <p className="p-5">Try again later Error found 404 ! </p>
          ) : (
            LatestVideo.map(({ id, link }) => (
              <div className="col-sm-6" key={id}>
                <iframe
                  width="560"
                  height="300"
                  src={link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            ))
          )}

          {/* {data.videos.home.map((video) => (
            <div className="col-sm-6">
              <iframe
                width="560"
                height="315"
                src={video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
};

export default Videos;
