import React from "react";
import Sidebar from "../../adminPanel/Sidebar";

const Feedback = () => {
  return (
    <>
      <Sidebar />
      <div className="ml" style={{ marginLeft: "10%", backgroundColor: "#f7f7ff" }}>
        <h4
          className="text-center text-success"
          style={{ margin: 0, paddingTop: "10px" }}
        >
          Mohalla Clinic Feedback
        </h4>
        <iframe
          src="https://sunnimohallaclinic.com/feedback"
          width="100%"
          height="748vh"
          frameborder="0"
          title="feedback"
          scrolling="no"
        >
          Loading…
        </iframe>
      </div>
    </>
  );
};

export default Feedback;
