import { addDoc, collection, Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { db } from "../firebase";

export default function LatestVideoAdd() {
  const [formData, setFormData] = useState({
    link: "",
    createdAt: Timestamp.now().toDate(),
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePublish = () => {
    if (!formData.link) {
      toast("Paste your Youtube embed link !", { type: "info" });
      return;
    }

    setFormData({
      link: "",
    });
    const articleRef = collection(db, "LatestVideo");
    addDoc(articleRef, {
      link: formData.link,
      createdAt: Timestamp.now().toDate(),
    })
      .then(() => {
        toast("Your video is published.", { type: "success" });
      })
      .catch((err) => {
        toast("Error found in adding", { type: "error" });
      });
  };
  return (
    <div className="border p-3 bg-light">
      <h2>Create Video </h2>
      <label htmlFor="">Paste your Youtube embed link</label>
      <input
        type="text"
        name="link"
        className="form-control"
        value={formData.link}
        onChange={(e) => handleChange(e)}
      />

      <br />
      <br />

      <button className="form-control btn-primary" onClick={handlePublish}>
        Publish
      </button>

      <br />
    </div>
  );
}
