import AdminNav from "./AdminNav";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import fireDb from "../../../firebase";

const SearchCollection = () => {
  const [Blood, setBlood] = useState({});
  const [Donor, setDonor] = useState({});

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let search = query.get("name");

  useEffect(() => {
    const searchBlood = () => {
      fireDb
        .child("BloodSystem/AddBlood")
        .orderByChild("name")
        .equalTo(search)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            const Blood = snapshot.val();
            setBlood(Blood);
          } else {
            setBlood({});
          }
        });
    };

    const searchDonor = () => {
      fireDb
        .child("BloodSystem/AddDonor")
        .orderByChild("name")
        .equalTo(search)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            const Donor = snapshot.val();
            setDonor(Donor);
          } else {
            setDonor({});
          }
        });
    };
    searchBlood();
    searchDonor();
  }, [search]);

  const onDelete = (id) => {
    if (window.confirm("Are you sure that you wanted to delete this Blood ?")) {
      fireDb.child(`BloodSystem/AddBlood/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Blood Deleted !");
        }
      });
    }
  };

  return (
    <>
      <div id="wrapper">
        <AdminNav title="Searching Collections" icon="fa fa-search fa-fw" />
        <div id="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className=".col-lg-12">
                <h1 className="page-header">Searching Blood</h1>
              </div>
            </div>

            <div className="row">
              <div className=".col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Blood Details
                    <Link to="/add-blood" className="addBtns">
                      Add More Blood
                    </Link>
                  </div>

                  <div className="panel-body">
                    <div className="table-responsive">
                      {Object.keys(Blood).length === 0 ? (
                        <h5>
                          No blood found in our system : "{query.get("name")}"
                        </h5>
                      ) : (
                        <table
                          className="table table-striped table-bordered table-hover"
                          id="dataTables-example"
                        >
                          <thead style={{ whiteSpace: "nowrap" }}>
                            <tr>
                              <th>Blood Group</th>
                              <th>Full Name</th>
                              <th>Gender</th>
                              <th>D.O.B</th>
                              <th>Weight</th>
                              <th>Address</th>
                              <th>Contact</th>
                              <th>Blood Quantity</th>
                              <th>Collection Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(Blood).map((id, index) => {
                              return (
                                <tr className="gradeA" key={id}>
                                  <td>{Blood[id].bloodgroup}</td>
                                  <td>{Blood[id].name}</td>
                                  <td>{Blood[id].gender}</td>
                                  <td>{Blood[id].dob}</td>
                                  <td>{Blood[id].weight}</td>
                                  <td>{Blood[id].address}</td>
                                  <td>{Blood[id].contact}</td>
                                  <td>{Blood[id].bloodqty}</td>
                                  <td>{Blood[id].collection}</td>
                                  <td
                                    style={{
                                      whiteSpace: "nowrap",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Link to={`/blood-update/${id}`}>
                                      <i
                                        className="fa fa-edit"
                                        style={{
                                          color: "green",
                                          marginRight: "10px",
                                        }}
                                      ></i>
                                    </Link>

                                    <i
                                      onClick={() => onDelete(id)}
                                      className="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className=".col-lg-12">
                <h1 className="page-header">Searching Donors</h1>
              </div>
            </div>

            <div className="row">
              <div className=".col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Donors Details
                    <Link to="/add-donor" className="addBtns">
                      Add New Donor
                    </Link>
                  </div>

                  <div className="panel-body">
                    <div className="table-responsive">
                      {Object.keys(Donor).length === 0 ? (
                        <h5>
                          No donors found in our system : "{query.get("name")}"
                        </h5>
                      ) : (
                        <table
                          style={{ whiteSpace: "nowrap" }}
                          className="table table-striped table-bordered table-hover"
                          id="dataTables-example"
                        >
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Username</th>
                              <th>Guardian's Name</th>
                              <th>Gender</th>
                              <th>D.O.B</th>
                              <th>Weight</th>
                              <th>Blood Group</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Contact</th>
                            </tr>
                          </thead>

                          <tbody>
                            {Object.keys(Donor).map((id, index) => {
                              return (
                                <tr key={id}>
                                  <td>{Donor[id].name}</td>
                                  <td>{Donor[id].username}</td>
                                  <td>{Donor[id].guardiansname}</td>
                                  <td>{Donor[id].gender}</td>
                                  <td>{Donor[id].dob}</td>
                                  <td>{Donor[id].weight}</td>
                                  <td>{Donor[id].bloodgroup}</td>
                                  <td>{Donor[id].email}</td>
                                  <td>{Donor[id].address}</td>
                                  <td>{Donor[id].contact}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchCollection;
