import React from "react";

const DonateBtn = () => {
  return (
    <>
      <div className="footer-ablove ">
        <div className="container">
          <div className="row">
            <p className="hidden-sm visible-lg">
              Your money will be used to help the needy people so please...
              <button
                data-toggle="modal"
                data-target="#myModal"
                className="btn btn-sm btn-donat"
              >
                {/* <i className="fa fa-inr"> </i> */}
                 Donate Now
              </button>
            </p>
          </div>
        </div>
      </div>

      <div style={{zIndex: "999999", marginTop: "15%"}} className="modal fade" id="myModal" tabIndex="-1" role="dialog"
    aria-labelledby="myModalLabel">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
          <h4 className="modal-title" id="myModalLabel">Donate Now</h4>
        </div>
        <div style={{padding: "0 20px 20px 20px"}} className="modal-body">

          <h3><b>Khidmat Foundation Nagpur</b></h3>
          <b>Bank Name : HDFC BANK</b><br />
          <b>Branch : Wardhman Nagar</b><br />
          <b>IFSC Code : HDFC0000502</b><br />
          <b>Account No.: 50200056526721</b><br />
          <img className="img-responsive" style={{maxHeight: "50%"}} src="images/barcode.png" alt="..." />

        </div>
      </div>
    </div>
  </div>
    </>
  );
};

export default DonateBtn;
