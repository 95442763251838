import React from 'react'
import Footer from '../components/Footer'
import DonorNav from './DonorNav'

const UserBloodAdded = () => {
  return (
    <>
    <div id="wrapper">

<DonorNav />
    <div id="page-wrapper">
        <div className="row">
            <div className="col-lg-12">
                <h1 className="page-header">DBMS</h1>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        MESSAGE BOX
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* <form role="form" action="index.php" method="post" /> */}
                        {/* <?php  */}

                        {/* if(isset($_POST['name'])){
                            $name = $_POST["name"];    
                            $gender = $_POST["gender"];
                            $dob = $_POST["dob"];
                            $weight = $_POST["weight"];
                            $bloodgroup = $_POST["bloodgroup"];
                            $address = $_POST["address"];
                            $contact = $_POST["contact"];
                            $bloodqty = $_POST["bloodqty"];
                            $collection = $_POST["collection"];

                            include '../pages/dbconnect.php';
                            //code after connection is successfull
                            $qry = "insert into blood(name,gender,dob,weight,bloodgroup,address,contact,bloodqty,collection) values ('$name','$gender','$dob','$weight','$bloodgroup','$address','$contact','$bloodqty','$collection')";
                            $result = mysqli_query($conn,$qry); //query executes

                            if(!$result){
                                echo"ERROR";
                            }else {
                                echo" <div style='text-align: center'><h1>Blood Donation Details Has Been Listed. Thank You.</h1>";
                                echo" <a href='userdashboard.php' div style='text-align: center'><h3>Go Back</h3>";

                            }

                        }else{
                            echo"<h3>YOU ARE NOT AUTHORIZED TO REDIRECT THIS PAGE. GO BACK to <a href='userdashboard.php'> DASHBOARD </a></h3>";
                        }


                        ?>         */}
                                {/* </form> */}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<Footer />
    </>
  )
}

export default UserBloodAdded