import React from "react";
import { useEffect, useState } from "react";
import fireDb from "../../../firebase";
import { Link } from "react-router-dom";
import AdminNav from "./AdminNav";

import { Line, Pie } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto'




const AdminDashboard = () => {
  const [Donors, setDonors] = useState({});
  const [Blood, setBlood] = useState({});
  const [Announcement, setAnnouncement] = useState({});

  useEffect(() => {
    fireDb.child("BloodSystem/AddDonor").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setDonors({ ...snapshot.val() });
      } else {
        setDonors({});
      }
    });
    return () => {
      setDonors({});
    };
  }, []);

  useEffect(() => {
    fireDb.child("BloodSystem/AddBlood").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setBlood({ ...snapshot.val() });
      } else {
        setBlood({});
      }
    });
    return () => {
      setBlood({});
    };
  }, []);

  useEffect(() => {
    fireDb.child("BloodSystem/Announcement").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setAnnouncement({ ...snapshot.val() });
      } else {
        setAnnouncement({});
      }
    });
    return () => {
      setAnnouncement({});
    };
  }, []);
  const data1 = {
    labels: ["AB+", "A+", "B+", "O+", "AB-", "A-", "B-", "O-"],
    datasets: [
        {
            data: [`${Object.keys(Blood).length}`, `${Object.keys(Donors).length}`, `${Object.keys(Donors).length}`, 10, 10, 10, 10, 10],
            backgroundColor: ["red", "blue", "green", "yellow", "darkorange", "#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["red", "blue", "green", "yellow", "darkorange", "#FF6384", "#36A2EB", "#FFCE56"],
        },
    ],
  };
  
  return (
    <div>
      <div id="wrapper">
        <AdminNav title="Admin Dashboard" icon="fa fa-dashboard fa-fw" />
        <div id="page-wrapper" className="navbar-collapse collapse in">
          <div className="row">
            <div className=".col-lg-12">
              <h1 className="page-header">Admin Dashboard</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="panel panel-primary">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-xs-3">
                      <i className="fa fa-users fa-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                      <div className="huge">{Object.keys(Donors).length}</div>
                      <div>Total Donors</div>
                    </div>
                  </div>
                </div>

                <Link to="/view-donor">
                  <div className="panel-footer">
                    <span className="pull-left">View Details</span>
                    <span className="pull-right">
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                    <div className="clearfix"></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="panel panel-green">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-xs-3">
                      <i className="icofont-blood icofont-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                      <div className="huge">{Object.keys(Blood).length}</div>
                      <div>Available Blood</div>
                    </div>
                  </div>
                </div>
                <Link to="/views-blood">
                  <div className="panel-footer">
                    <span className="pull-left">View Details</span>
                    <span className="pull-right">
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                    <div className="clearfix"></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="panel panel-yellow">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-xs-3">
                      <i className="fa fa-bullhorn fa-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                      <div className="huge">
                        {Object.keys(Announcement).length}
                      </div>
                      <div>Announcement</div>
                    </div>
                  </div>
                </div>
                <Link to="/views-announcement">
                  <div className="panel-footer">
                    <span className="pull-left">View Details</span>
                    <span className="pull-right">
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                    <div className="clearfix"></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="panel panel-red">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-xs-3">
                      <i className="icofont-blood-drop icofont-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                      <div className="huge">Donate</div>
                      <div>Blood</div>
                    </div>
                  </div>
                </div>
                <Link to="/add-blood">
                  <div className="panel-footer">
                    <span className="pull-left">Donate Blood Now!</span>
                    <span className="pull-right">
                      <i className="fa fa-arrow-circle-right"></i>
                    </span>
                    <div className="clearfix"></div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=".col-lg-12">
              <div id="content">
                <div className="container-fluid">
                  <div className="row-fluid">
                    <div className="span12">
                <Pie data={data1} width={400} height={300} options={{responsive: true, maintainAspectRatio: false, }} />

                      {/* <div
                        id="piechart"
                        style={{
                          width: "690px",
                          height: "320px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      ></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
