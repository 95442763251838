import React, { useState } from "react";
import { toast } from "react-toastify";
import Clinic from "./Clinic";
import Select from "react-select";

const AddPatient = () => {
  let [name, setname] = useState("");
  let [phone, setphone] = useState("");
  let [address, setaddress] = useState("");
  let [date, setdate] = useState("");
  let [time, settime] = useState("");
  let [branches, setbranches] = useState("");
  let [doctors, setdoctors] = useState("");
  let [amount, setamount] = useState("");

  const postData = async (e) => {
    e.preventDefault();

    if (
      name &&
      phone &&
      amount &&
      date &&
      time &&
      branches &&
      doctors &&
      address
    ) {
      const res = await fetch(
        "https://khidmat-foundation-default-rtdb.firebaseio.com/Patient_Info.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            phone: phone,
            address: address,
            amount: amount,
            date: date,
            time: time,
            branches: branches,
            doctors: doctors,
          }),
        }
      );

      if (res) {
        toast.success("Patient Added successfully");
        setname("");
        setphone("");
        setaddress("");
        setamount("");
        // setdate("");
        // settime("");
        // setbranches("");
        // setdoctors("");
      }
    } else {
      toast.error("Please fill out all required fields");
    }
  };

  const branche = [
    { value: "Kharbi", label: "Kharbi Main Branch Roshanbagh" },
    { value: "Tajbagh", label: "Tajbagh Shareef Yasin Plot" },
    { value: "Hasanbagh", label: "Hasanbagh Chandani Chowk" },
    { value: "Pelinadi", label: "Peli Nadi Tipu Sultan Chowk" },
  ];

  const times = [
    { value: "Morning", label: "Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Evening", label: "Evening" },
    { value: "Night", label: "Night" },
  ];

  const doctor = [
    { value: "Dr. Mansha Ansari", label: "Dr. Mansha Ansari" },
    { value: "Dr. Heena Sheikh", label: "Dr. Heena Sheikh" },
    {
      value: "Dr. Komal Donarkar",
      label: "Dr. Komal Donarkar",
    },
    {
      value: "Dr. Shahista Qureshi",
      label: "Dr. Shahista Qureshi",
    },
    {
      value: "Dr. Pooja Suryavanshi",
      label: "Dr. Pooja Suryavanshi",
    },
    {
      value: "Dr. Tayyaba Sarosh",
      label: "Dr. Tayyaba Sarosh",
    },
    { value: "Dr. Nizam Diwan", label: "Dr. Nizam Diwan" },
    { value: "Dr. D Vasnik", label: "Dr. D Vasnik" },
  ];

  const handleTime = (e) => {
    settime(e.value);
    console.log(e.value);
  };
  const handleBranch = (e) => {
    setbranches(e.value);
    console.log(e.value);
  };
  const handleDoctor = (e) => {
    setdoctors(e.value);
    console.log(e.value);
  };

  return (
    <div className="container-fluid">
      <div className="col-md-3 pl-md-5 text-left">
        <br />
        <h4 className="mb-3 text-center">Patient Information</h4>
        <form className="">
          <div className="form-group">
            <label htmlFor="">Checkup Date</label>
            <input
              onChange={(e) => setdate(e.target.value)}
              type="date"
              className="form-control"
              placeholder="Date"
              name="date"
              value={date}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Checkup Time</label>
            <Select
              placeholder="Select Time"
              name="time"
              options={times}
              value={times.value}
              onChange={handleTime}
              isClearable
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Branch</label>
            <Select
              placeholder="Select Branch"
              name="branches"
              value={branche.value}
              options={branche}
              onChange={handleBranch}
              isClearable
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Doctor</label>
            <Select
              placeholder="Select Doctor"
              name="doctors"
              value={doctor.value}
              options={doctor}
              onChange={handleDoctor}
              isClearable
            />
          </div>

          <div className="form-group">
            <label htmlFor="">Patient Name</label>
            <input
              onChange={(e) => setname(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={name}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Patient Mobile</label>
            <input
              onChange={(e) => setphone(e.target.value)}
              type="number"
              className="form-control"
              placeholder="Mobile"
              name="phone"
              value={phone}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Patient Address</label>
            <textarea
              id=""
              cols="30"
              rows="3"
              className="form-control"
              placeholder="Address"
              name="address"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="">Amount</label>
            <input
              onChange={(e) => setamount(e.target.value)}
              type="number"
              className="form-control"
              placeholder="Amount"
              name="amount"
              value={amount}
            />
          </div>

          <div className="form-group">
            <input
              onClick={postData}
              type="submit"
              className="btn btn-primary py-3 px-5 form-control"
              value="Add Patient Information"
            />
          </div>
        </form>
      </div>
      <div className="col-md-9 pl-md-5 text-left">
        <Clinic />
      </div>
    </div>
  );
};

export default AddPatient;
