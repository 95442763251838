import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import DeleteBlog from "./DeleteBlog";

const BlogsPost = () => {
  const [BlogsPost, setBlogsPost] = useState([]);
  useEffect(() => {
    const BlogsPostRef = collection(db, "BlogsPost");
    const q = query(BlogsPostRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const BlogsPost = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogsPost(BlogsPost);
      console.log(BlogsPost);
    });
  }, []);
  return (
    <>
      <div className="row hed">
        <h2>Latest Blogs View</h2>
      </div>

      {BlogsPost.length === 0 ? (
        <p className="p-5">Try again later Error found 404 ! </p>
      ) : (
        BlogsPost.map(
          ({ id, title, description, image, comment, createdAt }) => (
            <div className="col-sm-12 blll" key={id}>
              <div className="card row">
                <div className="col-sm-4">
                  <img alt="" src={image} />
                </div>

                <div className="col-sm-8">
                  <div className="topp blog-topp">
                    <strong className="mnt">
                      <i className="fa fa-calendar"></i>
                      {createdAt.toDate().toDateString()}
                    </strong>
                    <a href="/" className="comment">
                      <i className="fa fa-comments-o"> </i>
                       {comment} Comments
                    </a>
                  </div>
                  <h4>{title}</h4>
                  <p>{description}</p>
                  <DeleteBlog id={id} image={image} />
                </div>
              </div>
            </div>
          )
        )
      )}
    </>
  );
};

export default BlogsPost;
