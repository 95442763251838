import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
// import firebase from "firebase/compat/app";


const firebaseConfig = {
  apiKey: "AIzaSyBQYYYfsPKVEX_Jxia7Pdol_shOKOT1dn0",
  authDomain: "khidmat-foundation.firebaseapp.com",
  databaseURL: "https://khidmat-foundation-default-rtdb.firebaseio.com",
  projectId: "khidmat-foundation",
  storageBucket: "khidmat-foundation.appspot.com",
  messagingSenderId: "369133195745",
  appId: "1:369133195745:web:9ad85de78cd29ebd1782a0",
  measurementId: "G-F185BJRZE8",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);


export const messaging = getMessaging(app);
// export const fb = firebase(app);
export default app;
