import React from "react";
import { useEffect, useState } from "react";
import fireDb from "./firebase.js";
import { toast } from "react-toastify";
import Sidebar from "../adminPanel/Sidebar.jsx";

const DmView = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("direct-message").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const onDelete = (id) => {
    if (window.confirm("Are you sure that you wanted to delete this message ?")) {
      fireDb.child(`direct-message/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Message Deleted !");
        }
      });
    }
  };

  return (
    <>
    <Sidebar />
      <div className="ml" style={{ marginLeft: "10%", backgroundColor: "#f7f7ff" }}>
        <h4
          className="text-center text-success"
          style={{ margin: 0, padding: "10px" }}
        >
          Messages Details
        </h4>
      <div className="container my-5">
        <table className="table table-striped styled-table border">
          <thead className="bg-info text-white">
            <tr className="text-center">
              <th scope="col">No.</th>
              <th scope="col">Email</th>
              <th scope="col">Message</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map((id, index) => {
              return (
                <tr key={id} className="">
                  <th scope="row">{index + 1}</th>
                  <td>{data[id].email}</td>
                  <td>{data[id].message}</td>
                  <td>
                    <button
                      className="btn btn-delete mx-1"
                      onClick={() => onDelete(id)}
                    >
                      <i className="fa fa-trash text-danger" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      </div>
    </>
  );
};

export default DmView;
