import AdminNav from "../admin/AdminNav";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLinkClickHandler, useParams } from "react-router-dom";
import fireDb from "../../../firebase";

const initialState = {
  announcement: "",
  bloodneed: "",
  date: "",
  organizer: "",
  requirements: "",
};

const Announcement = () => {
  const [IsLoading, setIsLoading] = useState(false);

  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { announcement, bloodneed, date, organizer, requirements } = state;

  const history = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("BloodSystem/Announcement").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!announcement || !bloodneed || !date || !organizer || !requirements) {
      toast.error("Please fill all input field !");
    } else {
      if (!id) {
        fireDb.child("BloodSystem/Announcement").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Announcement Add Success");
          }
        });
      } else {
        fireDb.child(`BloodSystem/Announcement/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Announcement Updated Success");
          }
        });
      }
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      setTimeout(() => history("/views-announcement"), 500);
    }
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="orange"
            height={100}
            width={50}
          />
        </div>
      ) : (
        <div id="wrapper">
          <AdminNav title="Announcement" icon="fa fa-bullhorn fa-fw" />
          <div id="page-wrapper">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="page-header">
                  {id ? "Update" : "Make"} Announcement
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    Please fill up the form below:
                  </div>
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <form method="post" onSubmit={handleSubmit}>
                          <div class="form-group">
                            <label>Enter Announcement Title</label>
                            <input
                              value={announcement || ""}
                              onChange={handleInputChange}
                              class="form-control"
                              type="text"
                              placeholder="Announcement Title"
                              name="announcement"
                              required
                            />
                          </div>

                          <div class="form-group">
                            <label>Blood Needed (Type)</label>
                            <input
                              value={bloodneed || ""}
                              onChange={handleInputChange}
                              class="form-control"
                              type="text"
                              placeholder="example: B+"
                              name="bloodneed"
                              required
                            />
                          </div>

                          <div class="form-group">
                            <label>Date and Time</label>
                            <input
                              value={date || ""}
                              onChange={handleInputChange}
                              class="form-control"
                              type="date"
                              name="date"
                              required
                            />
                          </div>

                          <div class="form-group">
                            <label>Organized by</label>
                            <input
                              value={organizer || ""}
                              onChange={handleInputChange}
                              class="form-control"
                              placeholder="Enter Organizer's Name"
                              type="text"
                              name="organizer"
                              required
                            />
                          </div>

                          <div class="form-group">
                            <label>Requirements</label>
                            <textarea
                              value={requirements || ""}
                              onChange={handleInputChange}
                              class="form-control"
                              rows="4"
                              type="text"
                              name="requirements"
                              required
                            ></textarea>
                          </div>

                          <input
                            className="btn btn-success"
                            style={{ borderRadius: "0%" }}
                            type="submit"
                            value={id ? "Update" : "Save"}
                          />

                          {/* <button
                            onClick={postData}
                            type="submit"
                            style={{ borderRadius: "0%" }}
                            class="btn-success btn btn-default"
                          >
                            Submit
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Announcement;
