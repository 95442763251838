import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBQYYYfsPKVEX_Jxia7Pdol_shOKOT1dn0",
  authDomain: "khidmat-foundation.firebaseapp.com",
  databaseURL: "https://khidmat-foundation-default-rtdb.firebaseio.com",
  projectId: "khidmat-foundation",
  storageBucket: "khidmat-foundation.appspot.com",
  messagingSenderId: "369133195745",
  appId: "1:369133195745:web:9ad85de78cd29ebd1782a0",
  measurementId: "G-F185BJRZE8",
};

const fireDb = firebase.initializeApp(firebaseConfig);
export default fireDb.database().ref();
