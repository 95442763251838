// import data from "../data/data.json";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import CButton from "./CButton";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import Header from "./Header";

const Blogs = () => {
  const [LatestBlogs, setLatestBlogs] = useState([]);
  useEffect(() => {
    const LatestBlogsRef = collection(db, "LatestBlogs");
    const q = query(LatestBlogsRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const LatestBlogs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLatestBlogs(LatestBlogs);
      console.log(LatestBlogs);
    });
  }, []);

  const [BlogsAll, setBlogsAll] = useState([]);
  useEffect(() => {
    const BlogsAllRef = collection(db, "BlogsAll");
    const q = query(BlogsAllRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const BlogsAll = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBlogsAll(BlogsAll);
    });
  }, []);
  return (
    <>
      <Header />
      <div ng-controller="homeCtrl" className="home-cont">
        <div className="container-fluid sldddd">
          <div style={{ marginTop: "15px" }} className="container tmon">
            <div className="row hed">
              {/* <h2>Today Blogs</h2> */}
            </div>

            <div className="row">
              {LatestBlogs.length === 0 ? (
                <div className="row">
                  {/* {data.Blogs.home.map(
                    ({ image, date, view, title, discription }) => (
                      <div
                        style={{ marginBottom: "25px" }}
                        className="col-sm-12"
                      >
                        <div className="col-sm-4 imsec shado-1">
                          <img alt="" src={image} />
                        </div>
                        <div className="col-sm-6 nws">
                          <h2>{title}</h2>
                          <p>{discription}</p>
                        </div>
                      </div>
                    )
                  )} */}
                </div>
              ) : (
                LatestBlogs.map(
                  ({ id, title, description, image, comment, createdAt }) => (
                    <div
                      style={{ marginBottom: "25px" }}
                      className="col-sm-12"
                      key={id}
                    >
                      <div className="col-sm-4 imsec shado-1">
                        <img alt="" src={image} />
                      </div>
                      <div className="col-sm-6 nws">
                        <h2>{title}</h2>
                        <p>{description}</p>
                        {/* <div className="topp">
                        <strong className="mnt">
                          <i className="fa fa-calendar"></i>
                          {createdAt.toDate().toDateString()}
                        </strong>
                        <a className="comment" href="/">
                          <i className="fa fa-comments-o"></i> {comment}
                        </a>
                      </div> */}
                      </div>
                    </div>
                  )
                )
              )}
            </div>

            <div className="row hed">
              <h2>ALL Blogs</h2>
            </div>

            <div className="row">
              {BlogsAll.length === 0 ? (
                <div>
                  {/* {data.Blogs.viewAll.map(
                    ({ image, date, view, title, discription }) => (
                      <div className="col-sm-6">
                        <div className="col-sm-6 imsec">
                          <img className="shado-1" alt="" src={image} />
                        </div>
                        <div className="col-sm-6 nws">
                          <div className="topp">
                            <strong className="mnt">
                              <i className="fa fa-calendar"></i>
                              {date}
                            </strong>
                            <a className="comment" href="/">
                              <i className="fa fa-comments-o"></i> {view}
                            </a>
                            <h2>{title}</h2>
                            <p>{discription}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )} */}
                </div>
              ) : (
                BlogsAll.map(
                  ({ id, title, description, image, comment, createdAt }) => (
                    <div className="col-sm-6" key={id}>
                      <div className="col-sm-6 imsec">
                        <img className="shado-1" alt="" src={image} />
                      </div>
                      <div className="col-sm-6 nws">
                        <div className="topp">
                          <strong className="mnt">
                            <i className="fa fa-calendar"></i>
                            {createdAt.toDate().toDateString()}
                          </strong>
                          <a className="comment" href="/">
                            <i className="fa fa-comments-o"></i> {comment}
                          </a>
                          <h2>{title}</h2>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default Blogs;
