import React from "react";
import Header from "./Header";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import CButton from "./CButton";


const ALLvideos = () => {
  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "100px" }}>
        <h3> Our Videos </h3>

        <div className="elfsight-app-06bae381-5b04-42a0-93cb-c1ba0b38593c"></div>
      </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default ALLvideos;
