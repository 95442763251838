import React from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as FcIcons from "react-icons/fc";

export const SidebarData = [
{
	title: "Dashboard",
	path: "/admin-login",
	icon: <FcIcons.FcComboChart />,
},
{
	title: "Web Activity",
	path: "/admin-login",
	icon: <FcIcons.FcMultipleDevices />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	subNav: [
	{
		title: "News Activity",
		path: "/admin-login/dashbord/news",
		icon: <FcIcons.FcNews />,
	},
	{
		title: "Events Activity",
		path: "/admin-login/dashbord/events",
		icon: <FcIcons.FcCalendar />,
	},
	{
		title: "Blogs Activity",
		path: "/admin-login/dashbord/blogs",
		icon: <FcIcons.FcRules />,
	},
	{
		title: "Gallery Activity",
		path: "/admin-login/dashbord/gallery",
		icon: <FcIcons.FcMms />,
	},
	{
		title: "Videos Activity",
		path: "/admin-login/dashbord/videos",
		icon: <FcIcons.FcClapperboard />,
	},
	],
},
{
	title: "Database",
	path: "/admin-login",
	icon: <FcIcons.FcDataConfiguration />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	subNav: [
	{
		title: "Contacts",
		path: "/admin-login/database/contactview",
		icon: <FcIcons.FcContacts />,
		cName: "sub-nav",
	},
	{
		title: "Messages",
		path: "/admin-login/database/dmview",
		icon: <FcIcons.FcComments />,
		cName: "sub-nav",
	},
	{
		title: "Volunteers",
		path: "/admin-login/database/volunteerview",
		icon: <FcIcons.FcConferenceCall />,
	},
	{
		title: "Feedbacks",
		path: "/admin-login/hospital/feedback",
		icon: <FcIcons.FcCollaboration />,
	},
	],
},
{
	title: "Donations",
	path: "/donate",
	icon: <FcIcons.FcDonate />,
},
{
	title: "Hospital",
	path: "/admin-login/hospital",
	icon: <FcIcons.FcDepartment />,
},
{
	title: "Mohalla Clinic",
	path: "/admin-login/hospital/website",
	icon: <FcIcons.FcHome />,
},
{
	title: "Appoiments",
	path: "/admin-login/hospital/appointment",
	icon: <FcIcons.FcPlanner />,
},
{
	title: "User Manage",
	path: "/admin-login/users",
	icon: <FcIcons.FcVoicePresentation />,
},
{
	title: "Blood Banks",
	path: "/admin-dashboard",
	icon: <FcIcons.FcStumbleupon />,

	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	subNav: [
	{
		title: "Event 1",
		path: "/events/events1",
		icon: <IoIcons.IoIosPaper />,
	},
	{
		title: "Event 2",
		path: "/events/events2",
		icon: <IoIcons.IoIosPaper />,
	},
	],
},
{
	title: "Live Chats",
	path: "/admin-login/c",
	icon: <FcIcons.FcSms />,
},
];
