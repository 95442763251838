import { Timestamp } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

import data from "../data/data.json";

const Footer = () => {
  const [user, setUser] = useState({
    email: "",
    message: "",
    createdAt: Timestamp.now().toDate(),
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { email, message } = user;

    if (email && message) {
      const res = await fetch(
        "https://khidmat-foundation-default-rtdb.firebaseio.com/direct-message.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            message,
            createdAt: Timestamp.now().toDate(),
          }),
        }
      );

      if (res) {
        setUser({
          email: "",
          message: "",
          createdAt: Timestamp.now().toDate(),
        });
        toast.success("Message Send Successfully.");
      }
    } else {
      toast.error("Email and Message are Required !");
    }
  };
  return (
    <>
      <footer className="container-fluid footer-cont">
        <div className="container">
          <div className="footer-top row">
            <div className="col-md-4  foot-logo">
              <h1 className="text-uppercase">Khidmat Foundation</h1>
            </div>

            <div className="col-md-8  foot-addr">
              <br />
              <li className="list-unstyled">
                <i className="fa fa-globe"></i> Website :{" "}
                <a href="https://www.khidmat-foundation.com" className="">
                  www.khidmat-foundation.com
                </a>{" "}
              </li>
              <li className="list-unstyled">
                <i className="fa fa-phone-square"></i> Phone :{" "}
                <a href="tel:+91 7378500800"> +91 7378500800, </a>{" "}
                <a href="tel:+91 7378600800"> +91 7378600800 </a>{" "}
              </li>
              <li className="list-unstyled">
                <i className="fa fa-envelope"></i> Mail :{" "}
                <a href="mailto:nagpurkhidmatfoundation@gmail.com" className="">
                  nagpurkhidmatfoundation@gmail.com
                </a>{" "}
              </li>
            </div>
          </div>

          <div className="col-md-4">
            <div className="fotter-coo">
              <h3 className="text-light">About Us</h3>
              <p className="text-white font-weight-bold text-justify">
                Khidmat Foundation Nagpur is a Social Organization whose aim's
                to serve the poor people and helps them. Our NGO has been
                working since 6 years and at last year 2020, Khidmat Foundation
                doing more social works in our Society. We are serving duties
                and helping our needy peoples and we provide facility for poor
                people to take care there family at least money so that they can
                take advantage of it.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <h3 className="text-light">Working In</h3>
            <div className="fotter-coo text-uppercase">
              <i className="fa fa-caret-right"></i> Health <br />
              <i className="fa fa-caret-right"></i> Education <br />
              <i className="fa fa-caret-right"></i> Livelihood <br />
              <i className="fa fa-caret-right"></i> Child for Child <br />
              <i className="fa fa-caret-right"></i> Disaster Response <br />
              <i className="fa fa-caret-right"></i> Women Empowerment <br />
              <i className="fa fa-caret-right"></i> Empowering Grassroots <br />
            </div>
          </div>

          <div className="col-md-4 hidden-sm visible-lg">
            <div className="fotter-coo">
              <h4>PHOTO GALLERY</h4>
              <div className="gallery-row row">
                {data.footer.gallery.map((photo) => (
                  <div className="col-md-4 col-sm-6">
                    <img src={photo} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="">
                <h3 className="text-light">Address</h3>
                <address className="md-margin-bottom-40 text-white font-weight-bold text-justify">
                  Main Office <br />
                  Khidmat Foundation Nagpur
                  <br />
                  Near Raza Jama Masjid And
                  <br />
                  Khidmat Education Library <br />
                  Roshan Bagh Kharbi Road <br />
                  Nagpur Mh. India - 440034 <br />
                </address>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="font-weight-bold">
                <h3 className="text-light">Social Media</h3>
                <a
                  className="text-info"
                  href="https://www.twitter.com/Khidmatfoundationnagpur/"
                >
                  <i className="fa fa-twitter text-info"> </i> Twitter{" "}
                </a>{" "}
                <br />
                <a
                  className="text-danger"
                  href="https://www.youtube.com/channel/UC2ayb2ROklHGbUvbNyaWhcw"
                >
                  {" "}
                  <i className="fa fa-youtube-square text-danger">
                    {" "}
                  </i> Youtube{" "}
                </a>{" "}
                <br />
                <a href="https://www.facebook.com/Khidmatfoundationnagpur/">
                  <i className="fa fa-facebook-square"> </i> Facebook
                </a>{" "}
                <br />
                <a
                  className="text-warning"
                  href="https://www.instagram.com/khidmat_foundation_nagpur/"
                >
                  <i className="fa fa-instagram text-warning"> </i> Instagram
                </a>{" "}
                <br />
                <a className="text-info" href="/">
                  <i className="fa fa-linkedin-square text-info"> </i> Linked In{" "}
                </a>{" "}
                <br />
                <a
                  className="text-success"
                  href="https://chat.whatsapp.com/C0moBvA2Dh7Ff9nkd7bTj8"
                >
                  <i className="fa fa-whatsapp text-success"> </i> WhatsApp{" "}
                </a>
                <br />
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="">
                <h3 className="text-light">Quick Links</h3>
                <a href="/">
                  <i className="fa fa-home"></i> Home
                </a>
                <br />
                <a
                  href="https://www.sunnimohallaclinic.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <i className="fa fa-user-md"></i> Hospital
                </a>
                <br />
                <a href="/education">
                  <i className="fa fa-user"></i> Education
                </a>
                <br />
                <a href="/news">
                  <i className="fa fa-newspaper-o"></i> News
                </a>
                <br />
                <a href="/gallery">
                  <i className="fa fa-photo"></i> Gallery
                </a>
                <br />
                <a href="/support_us">
                  <i className="fa fa-credit-card"></i> Support Us
                </a>
                <br />
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
              <div className="">
                {/* <h3 className="text-light">Contact Us</h3> */}
                <h3 className="text-light">{data.footer.dm.name}</h3>
                <form className="my-form" action="/" method="post">
                  <div className="form-group">
                    <input
                      onChange={getUserData}
                      value={user.email}
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      onChange={getUserData}
                      value={user.message}
                      rows="3"
                      placeholder="Message"
                      className="form-control"
                      autoComplete="off"
                      type="text"
                      name="message"
                      required
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <button
                      className="form-control btn-primary"
                      type="submit"
                      onClick={postData}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="center">
            <div className="text-success">
              <strong>
                <p>
                  <a className="text-success" href="/">
                    {" "}
                    2022 | CopyRights © Khidmat Foundation Nagpur{" "}
                  </a>
                </p>
              </strong>
            </div>
          </div>
        </div>
      </footer>

      <center>
        <div className="copy bg-success">
          <div className="container">
            <a
              href="https://sarfaraztech.com"
              target={"_blank"}
              rel="noreferrer"
            >
              {" "}
              All Rights Reserved by SarfarazTech.Com{" "}
            </a>
            <span>
              <a
                href="https://github.com/SarfarazInfoTech"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="fa fa-github"></i>
              </a>
            </span>
          </div>
        </div>
      </center>
    </>
  );
};

export default Footer;
