import React from 'react'
import { Link } from 'react-router-dom'

const CButton = () => {
  return (
    <>
    <Link class="bottom-button" to="/c">
        <i class="fa fa-comments my-float"></i>
    </Link>
    </>
  )
}

export default CButton