import React from 'react'
import Sidebar from '../../adminPanel/Sidebar'

const Website = () => {
  return (
    <>
    <Sidebar />
      <div className="ml" style={{ marginLeft: "10%", background: 'gainsboro' }}>
        <h4
          className="text-center text-primary"
          style={{ margin: 0, paddingTop: "5px" }}
        >
          Clinic Website Management
        </h4>
    <iframe
        src="https://sunnimohallaclinic.com/api"
        width="100%"
        height="748vh"
        frameborder="0"
        title="api"
        scrolling="no"
      >
        Loading…
      </iframe>
      </div>
    </>
  )
}

export default Website