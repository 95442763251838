// import data from "../data/data.json";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import CButton from "./CButton";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import Header from "./Header";

const News = () => {
  const [LatestNews, setLatestNews] = useState([]);
  useEffect(() => {
    const LatestNewsRef = collection(db, "LatestNews");
    const q = query(LatestNewsRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const LatestNews = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLatestNews(LatestNews);
      console.log(LatestNews);
    });
  }, []);

  const [NewsAll, setNewsAll] = useState([]);
  useEffect(() => {
    const NewsAllRef = collection(db, "NewsAll");
    const q = query(NewsAllRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const NewsAll = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNewsAll(NewsAll);
    });
  }, []);
  return (
    <>
      <Header />
      <div ng-controller="homeCtrl" className="home-cont">
        <div className="container-fluid sldddd">
          <div style={{ marginTop: "15px" }} className="container tmon">
            <div className="row hed">
              <h2>Today News</h2>
            </div>

            <div className="row">
              {LatestNews.length === 0 ? (
                <div className="row">
                  {/* {data.news.home.map(
                    ({ image, date, view, title, discription }) => (
                      <div
                        style={{ marginBottom: "25px" }}
                        className="col-sm-12"
                      >
                        <div className="col-sm-4 imsec shado-1">
                          <img alt="" src={image} />
                        </div>
                        <div className="col-sm-6 nws">
                          <h2>{title}</h2>
                          <p>{discription}</p>
                        </div>
                      </div>
                    )
                  )} */}
                </div>
              ) : (
                LatestNews.map(
                  ({ id, title, description, image, views, createdAt }) => (
                    <div
                      style={{ marginBottom: "25px" }}
                      className="col-sm-12"
                      key={id}
                    >
                      <div className="col-sm-4 imsec shado-1">
                        <img alt="" src={image} />
                      </div>
                      <div className="col-sm-6 nws">
                        <h2>{title}</h2>
                        <p>{description}</p>
                        {/* <div className="topp">
                        <strong className="mnt">
                          <i className="fa fa-calendar"></i>
                          {createdAt.toDate().toDateString()}
                        </strong>
                        <a className="comment" href="/">
                          <i className="fa fa-eye"></i> {views}
                        </a>
                      </div> */}
                      </div>
                    </div>
                  )
                )
              )}
            </div>

            <div className="row hed">
              <h2>ALL NEWS</h2>
            </div>

            <div className="row">
              {NewsAll.length === 0 ? (
                <div>
                  {/* {data.news.viewAll.map(
                    ({ image, date, view, title, discription }) => (
                      <div className="col-sm-6">
                        <div className="col-sm-6 imsec">
                          <img className="shado-1" alt="" src={image} />
                        </div>
                        <div className="col-sm-6 nws">
                          <div className="topp">
                            <strong className="mnt">
                              <i className="fa fa-calendar"></i>
                              {date}
                            </strong>
                            <a className="comment" href="/">
                              <i className="fa fa-eye"></i> {view}
                            </a>
                            <h2>{title}</h2>
                            <p>{discription}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )} */}
                </div>
              ) : (
                NewsAll.map(
                  ({ id, title, description, image, views, createdAt }) => (
                    <div className="col-sm-6" key={id}>
                      <div className="col-sm-6 imsec">
                        <img className="shado-1" alt="" src={image} />
                      </div>
                      <div className="col-sm-6 nws">
                        <div className="topp">
                          <strong className="mnt">
                            <i className="fa fa-calendar"></i>
                            {createdAt.toDate().toDateString()}
                          </strong>
                          <a className="comment" href="/">
                            <i className="fa fa-eye"></i> {views}
                          </a>
                          <h2>{title}</h2>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default News;
