import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
// import data from "../data/data.json";
// import DeleteArticle from "./DeleteArticle";

const LatestEvents = () => {
  const [UpcomingEvents, setUpcomingEvents] = useState([]);
  useEffect(() => {
    const UpcomingEventsRef = collection(db, "UpcomingEvents");
    const q = query(UpcomingEventsRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const UpcomingEvents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUpcomingEvents(UpcomingEvents);
      console.log(UpcomingEvents);
    });
  }, []);
  return (
    <>
      <div className="row events">
        <div className="container">
          <div className="row hed">
            <h2>Events</h2>
          </div>

          <div className="row">
            {UpcomingEvents.length === 0 ? (
              <p className="p-5">Try again later Error found 404 ! </p>
            ) : (
              UpcomingEvents.map(({ id, title, image, createdAt, url }) => (
                <div className="col-sm-4" key={id}>
                  <div className="upcoming-box">
                    <div className="frame">
                      <a href={url}>
                        <img alt="img" src={image} />
                      </a>
                    </div>
                    <div className="caption">
                      <strong className="title">{title}</strong>
                      <strong className="mnt">
                        <span>{createdAt.toDate().toDateString()}</span>
                      </strong>
                    </div>
                  </div>
                </div>
              ))
            )}

            {/* {data.events.home.map(({ image, date, title, url }) => (
              <div className="col-sm-4">
                <div className="upcoming-box">
                  <div className="frame">
                    <a href={url}>
                      <img alt="img" src={image} />
                    </a>
                  </div>
                  <div className="caption">
                    <strong className="title">{title}</strong>
                    <strong className="mnt">
                      <span>{date}</span>
                    </strong>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestEvents;
