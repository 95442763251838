import React, { useState } from "react";
import DonorNav from "./DonorNav";
import Footer from "../components/Footer";
import ReactLoading from "react-loading";

const UserViewBlood = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const Loader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="#d9534f"
            height={100}
            width={50}
          />
          <DonorNav />
        </div>
      ) : (
        <>
          <DonorNav />
          <div id="wrapper" onClick={Loader}>
            <div id="page-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className=".col-lg-12">
                    <h1 className="page-header">Blood Collection</h1>
                  </div>
                </div>

                <div className="row">
                  <div className=".col-lg-12">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        Total Records of available bloods
                      </div>

                      <div className="panel-body">
                        <div className="table-responsive">
                          <table
                            className="table table-striped table-bordered table-hover"
                            id="dataTables-example"
                          >
                            <thead>
                              <tr>
                                <th>Blood Group</th>
                                <th>Full Name</th>
                                <th>Gender</th>
                                <th>D.O.B</th>
                                <th>Weight</th>
                                <th>Address</th>
                                <th>Contact</th>
                                <th>Blood Quantity</th>
                                <th>Collection Date</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr className="gradeA">
                                <td>".$row['bloodgroup']."</td>
                                <td>".$row['name']."</td>
                                <td>".$row['gender']."</td>
                                <td>".$row['dob']."</td>
                                <td>".$row['weight']."</td>
                                <td>".$row['address']."</td>
                                <td>".$row['contact']."</td>
                                <td>".$row['bloodqty']."</td>
                                <td>".$row['collection']."</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default UserViewBlood;
