import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserAuth } from "../context/UserAuthContextProvider";

const SignUp = ({admin}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { signUp } = useUserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password);
      navigate("/admin");
    } catch (err) {
      toast(`Try again ! ${error && error}`, { type: "error" });
      setError(err.message);
    }
  };
  return (
    <>
      <div className="container">
        <div className="row hed">
          <h2>{admin}Admin SignUp</h2>
        </div>
        <div className="col-md-4">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-10 col-sm-10">
                <i className="fa fa-user"></i> New Username
                <input
                  className="form-control input-sm"
                  placeholder="Username"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
              </div>

              <div className="col-md-10 col-sm-10">
                <i className="fa fa-lock"></i> New Password
                <input
                  className="form-control input-sm"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <br />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control btn-success"
                  type="submit"
                  value="SignUp"
                />
                <br />
              </div>
            </div>
          </form>
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div>
    </>
  );
};

export default SignUp;
