import React from "react";
import { Link } from "react-router-dom";
import AdminNav from "./AdminNav";
import { useEffect, useState } from "react";
import fireDb from "../../../firebase";

const EditDonor = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("BloodSystem/AddDonor").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  return (
    <div id="wrapper">
      <AdminNav title="Update Donor" icon="icofont-blood-drop fa-fw" />
      <div id="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className=".col-lg-12">
              <h1 className="page-header">Donors Detail</h1>
            </div>
          </div>

          <div className="row">
            <div className=".col-lg-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  Donors Details
                  <Link to="/add-donor" className="addBtns">
                    Add New Donor
                  </Link>
                </div>

                <div className="panel-body">
                  <div className="table-responsive">
                    <table
                      style={{ whiteSpace: "nowrap" }}
                      className="table table-striped table-bordered table-hover"
                      id="dataTables-example"
                    >
                      <thead>
                        <tr>
                          <th>Donor's Id</th>
                          <th>Name</th>
                          <th>Username</th>
                          <th>Guardian's Name</th>
                          <th>Gender</th>
                          <th>D.O.B</th>
                          <th>Weight</th>
                          <th>Blood Group</th>
                          <th>Email</th>
                          <th>Address</th>
                          <th>Contact</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {Object.keys(data).map((id, index) => {
                          return (
                            <tr key={id}>
                              <td>
                                {index ** 0}
                                {index + 10}
                              </td>
                              <td>{data[id].name}</td>
                              <td>{data[id].username}</td>
                              <td>{data[id].guardiansname}</td>
                              <td>{data[id].gender}</td>
                              <td>{data[id].dob}</td>
                              <td>{data[id].weight}</td>
                              <td>{data[id].bloodgroup}</td>
                              <td>{data[id].email}</td>
                              <td>{data[id].address}</td>
                              <td>{data[id].contact}</td>
                              <td>
                                <Link to={`/donor-update/${id}`}>
                                  <i className="fa fa-edit"> </i> Update
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDonor;
