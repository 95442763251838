import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import DeleteGallery from "./DeleteGallery";

export const GalleryView = () => {
  const [GalleryView, setGalleryView] = useState([]);
  useEffect(() => {
    const GalleryViewRef = collection(db, "Gallery");
    const q = query(GalleryViewRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const GalleryView = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGalleryView(GalleryView);
      console.log(GalleryView);
    });
  }, []);
  return (
    <>
      <div className="row hed">
        <h2>Add Gallery</h2>
      </div>

      {GalleryView.length === 0 ? (
        <p className="p-5">Try again later Error found 404 ! </p>
      ) : (
        GalleryView.map(({ id, image, url }) => (
          <div className="col-md-4 pb-4 mb-3" key={id}>
            <img
              className="img-thumbnail"
              src={image}
              alt="images"
              width="500"
              height="500"
            />
            <div className="pt-4 mt-3">
              <br />
              <DeleteGallery id={id} image={image} />
            </div>
          </div>
        ))
      )}
    </>
  );
};
