import React, { useState } from "react";
import { Link } from "react-router-dom";

const DonorNav = () => {
  const [bootstrap] = useState("../vendor/bootstrap/css/bootstrap.min.css");
  const [metisMenu] = useState("../vendor/metisMenu/metisMenu.min.css");
  const [admin2] = useState("../dist/css/sb-admin-2.css");
  const [morrisjs] = useState("../vendor/morrisjs/morris.css");
  const [fontawesome] = useState("../vendor/font-awesome/css/font-awesome.min.css");
  const [icofont] = useState("../icofont/icofont.min.css");

  const [jquery] = useState("../vendor/jquery/jquery.min.js");
  const [bootstrapjs] = useState("../vendor/bootstrap/js/bootstrap.min.js");
  const [metisMenujs] = useState("../vendor/metisMenu/metisMenu.min.js");
  const [raphael] = useState("../vendor/raphael/raphael.min.js");
  const [morrisjsjs] = useState("../vendor/morrisjs/morris.min.js");
  const [morrisdata] = useState("../data/morris-data.js");
  const [sbadmin] = useState("../dist/js/sb-admin-2.js");

  return (
    <>
      <link href={bootstrap} rel="stylesheet" type="text/css" />
      <link href={metisMenu} rel="stylesheet" type="text/css" />
      <link href={admin2} rel="stylesheet" type="text/css" />
      <link href={morrisjs} rel="stylesheet" type="text/css" />
      <link href={fontawesome} rel="stylesheet" type="text/css" />
      <link href={icofont} rel="stylesheet" type="text/css" />

      <nav
        className="navbar navbar-default navbar-static-top"
        role="navigation"
        style={{ marginBottom: "0" }}
      >
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand" to="/user-dashboard">
            <i className="icofont-blood-drop text-danger"></i>Blood Donor System
          </Link>
        </div>

        <ul className="nav navbar-top-links navbar-right">
          <li className="dropdown">
            <Link className="dropdown-toggle" data-toggle="dropdown" to="#">
              <i className="fa fa-user fa-fw"></i> <i className="fa fa-caret-down"></i>
            </Link>
            <ul className="dropdown-menu dropdown-user">
              {/* <!-- <li className="divider"></li> --> */}
              <li>
                <Link to="logout.php">
                  <i className="fa fa-sign-out fa-fw"></i> Logout
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <div className="navbar-default sidebar" role="navigation">
          <div className="sidebar-nav navbar-collapse">
            <ul className="nav" id="side-menu">
              <li className="sidebar-search">
                <div className="input-group custom-search-form">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="input-group-btn">
                    <button className="btn btn-default" type="button">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </li>
              <li>
                <Link to="/user-dashboard">
                  <i className="fa fa-dashboard fa-fw"></i> Donor's Dashboard
                </Link>
              </li>
              <li>
                <Link to="/UserAddBlood">
                  <i className="icofont-blood-drop fa-fw"></i> Blood Donation Now

                </Link>
              </li>
              <li>
                <Link to="/view-blood">
                  <i className="fa fa-heartbeat fa-fw"></i> View Blood Collections
                </Link>
              </li>
              <li>
                <Link to="/view-announcement">
                  <i className="fa fa-bullhorn fa-fw"></i> View Announcements
                </Link>
              </li>
              <li>
                <Link to="/view-campaigns">
                  <i className="fa fa-flag fa-fw"></i> View Campaigns
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <script src={jquery}></script>
      <script src={bootstrapjs}></script>
      <script src={metisMenujs}></script>
      <script src={raphael}></script>
      <script src={morrisjsjs}></script>
      <script src={morrisdata}></script>
      <script src={sbadmin}></script>

    </>
  );
};

export default DonorNav;
