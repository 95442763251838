import AdminNav from "../admin/AdminNav";
import { Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fireDb from "../../../firebase";

const initialState = {
  name: "",
  gender: "",
  dob: "",
  weight: "",
  address: "",
  bloodgroup: "",
  contact: "",
  bloodqty: "",
  collection: Timestamp.now().toDate(),
};

const Blood = () => {
  const [IsLoading, setIsLoading] = useState(false);

  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { name, gender, dob, weight, address, bloodgroup, contact, bloodqty, collection } =
    state;

  const history = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("BloodSystem/AddBlood").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }
    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !name ||
      !gender ||
      !dob ||
      !weight ||
      !address ||
      !bloodgroup ||
      !contact ||
      !bloodqty ||
      !collection
    ) {
      toast.error("Please fill all input field !");
    } else {
      if (!id) {
        fireDb.child("BloodSystem/AddBlood").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Blood Added Success");
          }
        });
      } else {
        fireDb.child(`BloodSystem/AddBlood/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Blood Updated Success");
          }
        });
      }
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      setTimeout(() => history("/views-blood"), 500);
    }
  };

  return (
    <>
      {IsLoading ? (
        <div className="loader">
          <ReactLoading
            className="spinner"
            type="spokes"
            color="orange"
            height={100}
            width={50}
          />
        </div>
      ) : (
        <div id="wrapper">
          <AdminNav title={id ? "Update Blood Details" : "Add Blood Details"} icon={id ? "fa fa-edit fa-fw" : "fa fa-user-plus fa-fw"} />
          <div id="page-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="page-header">{id ? "Update" : "Add"} Blood Details</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    Please fill up the form below:
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <form method="post" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Full Name</label>
                            <input
                              value={name || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="text"
                              placeholder="Enter your full name"
                              name="name"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Gender [ M/F ]</label>
                            <input
                              value={gender || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="M or F"
                              name="gender"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Date of birth</label>
                            <input
                              value={dob || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="date"
                              name="dob"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Weight</label>
                            <input
                              value={weight || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Weight"
                              type="number"
                              name="weight"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Blood Group</label>
                            <input
                              value={bloodgroup || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Eg: B+"
                              name="bloodgroup"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Address</label>
                            <input
                              value={address || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Address"
                              type="text"
                              name="address"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Contact Number</label>
                            <input
                              value={contact || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Contact Number"
                              type="number"
                              name="contact"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Blood Quantity</label>
                            <input
                              value={bloodqty || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Blood Quantity"
                              type="number"
                              name="bloodqty"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label>Collection Date</label>
                            <input
                              value={collection || ""}
                              onChange={handleInputChange}
                              className="form-control"
                              type="date"
                              name="collection"
                              required
                            />
                          </div>
                          <input
                            className="btn btn-success"
                            style={{ borderRadius: "0%" }}
                            type="submit"
                            value={id ? "Update" : "Submit Form"}
                          />
                          {/* <button
                            onClick={postData}
                            type="submit"
                            className="btn btn-success btn-default"
                            style={{ borderRadius: "0%" }}
                          >
                            Submit Form
                          </button> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Blood;
