import React from 'react'
import { useUserAuth } from "../context/UserAuthContextProvider";

export const UserLogout = () => {
    const { user, logOut } = useUserAuth();
  // console.log(user);
  const handleLogOut = async () => {
    try {
      await logOut();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
        <div>
          <h4 className="text-primary">
            Admin Login Success
            {/* <button
              style={{ float: "right" }}
              type="submit"
              className="btn btn-danger"
              onClick={handleLogOut}
            >
              Logout =<i class="fa fa-sign-out"> </i>
            </button> */}
          </h4>
          {user && <p>Hello, {user.email}</p>}
        </div>
    </>
  )
}
