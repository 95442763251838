import React from 'react'
import CButton from './CButton'
import DonateBtn from './DonateBtn'
import Footer from './Footer'
import Header from './Header'

const AboutUs = () => {
  return (
    <>
      <Header />
    <div className="home-cont">
    <div className="container-fluid sldddd">
      <div className=" row hidden-xs">
        <div style={{height: "500px", marginTop: "0"}} className="slider img-back">

        </div>
      </div>

      <div className="container add-tionn">
        <div className="row">
          <div className="col-sm-6">
            <img alt='' style={{width: "100%"}} src="assets/images/charity1.jpg" />
          </div>

          <div className="col-sm-6 abut">
            <div className="row hed">
              <h2>About Us</h2>
            </div>
            <p>
              Khidmat Foundation is a Social Organization whose aim's to serve the poor people and helps them. Our NGO
              has been working since 6 years and at last year 2020, Khidmat Foundation doing more social works in our
              Society.
            </p>

            <b>Our Aim</b>
            <ul>
              <li> To conduct vocational training programs. </li>
              <li> To provide educational guidance for the needy. </li>
              <li> To assist students and graduates to avail job opportunities. </li>
              <li> To guide the public to get medical facilities on our Health Care Center. </li>
              <li> To provide guidance to the public to get the government facilities, etc. </li>
              <li> To create awareness about the government schemes and policies of developmental programs to the society. </li>
              <li> We are donating medicines in Health Check-Up camp in free of cost so everyone can take advantage of it. </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row hed">
          <h2>Our Team</h2>
        </div>

        <div className="row team">
          <div className="col-sm-4">
            <b>Mr. Kingsly</b>
            <small>(President)</small>
            <p>The President of Thankam Social Awareness Foundation Trust is Mr. Kingsly, s/o. Thomas, aged

              34 years, residing at Myparambil, South Chemmantharai, Thiruvattar village, Thiruvarambu post,

              Kalkulam Thaluk, Kanyakumari District.</p>
          </div>

          <div className="col-sm-4">
            <b>Mrs. Jebin Sugitha </b>
            <small>(Secretary)</small>
            <p>The Secretary of Thankam Social Awareness Foundation Trust is
              Mrs. Jebin Sugitha, W/o. Kingsly Thomas, aged 30 years, residing at Myparambil,
              South- Chemmantharai, Thiruvattar Village, Thiruvarambu Post, Kalkulam Taluk,
              Kanyakumari District.</p>
          </div>

          <div className="col-sm-4">
            <b>Mr. Yesudhas</b>
            <small>(Treasurer)</small>
            <p>The Treasurer of Thankam Social Awareness Foundation Trust is Mr. Yesudhas, s/o. Chellakan,

              aged 60 years, Door no 1-320, Karumputhottam, Cherukole, Kalkulam Thaluk, Kanyakumari District.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  )
}

export default AboutUs