import React from "react";
import LatestNewsAdd from "./LatestNews";
import LatestNews from "./Latest-News";

const AddNews = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <LatestNews />
          </div>
          <div className="col-md-3">
            <LatestNewsAdd />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNews;
