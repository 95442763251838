import React from "react";

export default function Topbar({ setMenuStatus }) {
  return (
    <i
      type="button"
      onClick={() => {
        setMenuStatus(true);
      }}
      className="fa fa-navicon smal-menu-ico cp"
    ></i>
  );
}
