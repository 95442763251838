import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import DeleteVideo from "./DeleteVideo";

const LatestVideo = () => {
  const [LatestVideo, setLatestVideo] = useState([]);
  useEffect(() => {
    const LatestVideoRef = collection(db, "LatestVideo");
    const q = query(LatestVideoRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const LatestVideo = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLatestVideo(LatestVideo);
      console.log(LatestVideo);
    });
  }, []);
  return (
    <>
      <div className="row hed">
        <h2>Add Videos</h2>
      </div>

      {LatestVideo.length === 0 ? (
        <p className="p-5">Try again later Error found 404 ! </p>
      ) : (
        LatestVideo.map(({ id, link }) => (
          <div className="col-md-6" key={id}>
            <iframe
              width="100%"
              height="200"
              src={link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <DeleteVideo id={id}/>
            <br />
          </div>
        ))
      )}
    </>
  );
};

export default LatestVideo;
