import React from "react";
import data from "../data/data.json";
import MobileMenus from "./MobileMenus";

const Header = () => {
  return (
    <>
      <div style={{ zIndex: "99999" }} className="container-fluid">
        <MobileMenus />

        <header className="hidden-xs">
          <nav style={{ position: "absolute" }} className="navmenu">
            <div style={{ margin: "auto" }}>
              <div className="nav-content shado-1">
                <div className="container">
                  <div
                    style={{ height: "94px", overflow: "hidden" }}
                    className="col-md-4 col-sm-12"
                  >
                    <img
                      style={{ width: "90px" }}
                      className="logo"
                      alt=""
                      src={data.logo}
                    />
                    <h1 style={{ marginLeft: "4px" }}>Khidmat Foundation</h1>
                    <b style={{ marginLeft: "9px" }}>
                      Nagpur Charity Organization
                    </b>
                  </div>
                  <div
                    style={{ height: "94px", overflow: "hidden" }}
                    className="col-md-8 hidden-sm"
                  >
                    <button
                      data-toggle="modal"
                      data-target="#myModal"
                      className="btn btn-sm btn-donat"
                    >
                      <i className="fa fa-inr"></i> Donate Now
                    </button>
                    <ul className="top-right">
                      <a href="/">
                        <li>
                          <i className="fa fa-linkedin-square"></i>
                        </li>
                      </a>
                      <a href="https://www.twitter.com/Khidmatfoundationnagpur/">
                        <li>
                          <i className="fa fa-twitter"></i>
                        </li>
                      </a>
                      <a href="https://chat.whatsapp.com/C0moBvA2Dh7Ff9nkd7bTj8">
                        <li>
                          <i className="fa fa-whatsapp"></i>
                        </li>
                      </a>
                      <a href="https://www.youtube.com/channel/UC2ayb2ROklHGbUvbNyaWhcw">
                        <li>
                          <i className="fa fa-youtube-square"></i>
                        </li>
                      </a>
                      <a href="https://www.instagram.com/khidmat_foundation_nagpur/">
                        <li>
                          <i className="fa fa-instagram"></i>
                        </li>
                      </a>
                      <a href="https://www.facebook.com/Khidmatfoundationnagpur/">
                        <li>
                          <i className="fa fa-facebook-square"></i>
                        </li>
                      </a>
                    </ul>
                    <div className="exu">
                      <i className="fa fa-phone"></i> : +91 7378600800
                      <i
                        style={{ marginLeft: "30px" }}
                        className="fa fa-envelope"
                      ></i>{" "}
                      : nagpurkhidmatfoundation@gmail.com
                    </div>
                  </div>
                </div>
                <div></div>
              </div>

              <div className="my-nav shado-1">
                <div className="container">
                  <ul className="nav-menu1">
                    <a href="/">
                      <li id="mm1" className="active  mom">
                        <i className="fa fa-home"></i>
                        Home
                      </li>
                    </a>
                    <a
                      href="https://www.sunnimohallaclinic.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <li className="mom" id="mm8">
                        <i className="fa fa-user-md"></i>
                        Hospital
                      </li>
                    </a>
                    <a href="/education">
                      <li className="mom" id="mm5">
                        <i className="fa fa-user"></i>
                        Education
                      </li>
                    </a>
                    <a href="/news">
                      <li className="mom" id="mm6">
                        <i className="fa fa-newspaper-o"></i>
                        News
                      </li>
                    </a>
                    <a href="/gallery">
                      <li className="mom" id="mm4">
                        <i className="fa fa-photo"></i>
                        Gallery
                      </li>
                    </a>
                    <a href="/about_us">
                      <li className="mom" id="mm2">
                        <i className="fa fa-shield"></i>
                        About Us
                      </li>
                    </a>
                    <a href="/support_us">
                      <li className="mom" id="mm3">
                        <i className="fa fa-credit-card"></i>
                        Support Us
                      </li>
                    </a>
                    <a href="/contact_us">
                      <li className="mom" id="mm7">
                        <i className="fa fa-map-marker"></i>
                        Contact Us
                      </li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          <div className="header-top shado-6">
            <div style={{ zIndex: "999999" }} className="container">
              <ul className="top-left"></ul>
              <ul className="top-right">
                <li>
                  <i className="fa fa"></i>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
