import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Education from "./components/Education";
import News from "./components/News";
import Gallery from "./components/Gallery";
import AboutUs from "./components/AboutUs";
import SupportUs from "./components/SupportUs";
import ContactUs from "./components/ContactUs";
import Joining from "./components/Joining";
import FourZeroFour from "./components/FourZeroFour";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminLogin } from "./backend/AdminLogin";
import { Dashbord } from "./backend/Dashbord";
import { Database } from "./backend/Database";
import Admin from "./backend/Admin";
import AddEvents from "./backend/Events";
import AddVideos from "./backend/Videos";
// import SignUp from "./backend/SignUp";
import UserAuthContextProvider from "./context/UserAuthContextProvider";
import ProtectedRoute from "./backend/ProtectedRoute";
// import { GalleryView } from "./backend/GalleryView";
import { AddGallery } from "./backend/Gallery";
import ALLvideos from "./components/ALLvideos";
import ContactView from "./backend/ContactView";
import DmView from "./backend/DmView";
import VolunteerView from "./backend/VolunteerView";
import UsersAdd from "./backend/UsersAdd";
import Hospital from "./backend/Hospital/Hospital";
import Website from "./backend/Hospital/Website";
import Feedback from "./backend/Hospital/Feedback";
import Appointment from "./backend/Hospital/Appointment";
import View from "./backend/Hospital/View";
import TabNews from "./backend/TabNews";
import TabBlogs from "./backend/TabBlogs";

import Join from "./components/Join/Join";
import Chat from "./components/Chat/Chat";
import Blogs from "./components/Blogs";

import UserViewCampaigns from "./backend/Hospital/BloodSystem/userslog/UserViewCampaigns";
import UserViewAnnouncement from "./backend/Hospital/BloodSystem/userslog/UserViewAnnouncement";
import UserAddBlood from "./backend/Hospital/BloodSystem/userslog/UserAddBlood";
import UserBloodAdded from "./backend/Hospital/BloodSystem/userslog/UserBloodAdded";
import UsersDashboard from "./backend/Hospital/BloodSystem/userslog/UsersDashboard";
import UsersLogin from "./backend/Hospital/BloodSystem/userslog/UsersLogin";
import UserViewBlood from "./backend/Hospital/BloodSystem/userslog/UserViewBlood";
import AdminDashboard from "./backend/Hospital/BloodSystem/admin/AdminDashboard";
import ViewDoner from "./backend/Hospital/BloodSystem/admin/ViewDoner";
import ViewBlood from "./backend/Hospital/BloodSystem/admin/ViewBlood";
import ViewCampaign from "./backend/Hospital/BloodSystem/admin/ViewCampaign";
import ViewAnnouncement from "./backend/Hospital/BloodSystem/admin/ViewAnnouncement";
import Campaign from "./backend/Hospital/BloodSystem/backend/Campaign";
import Announcement from "./backend/Hospital/BloodSystem/backend/Announcement";
import Donor from "./backend/Hospital/BloodSystem/backend/Donor";
import EditDonor from "./backend/Hospital/BloodSystem/admin/EditDonor";
import DeleteDonor from "./backend/Hospital/BloodSystem/admin/DeleteDonor";
import Blood from "./backend/Hospital/BloodSystem/backend/Blood";
import SearchCollection from "./backend/Hospital/BloodSystem/admin/SearchCollection";
import Url from "./Url";
import Clinic from "./backend/Hospital/Management/Clinic";
import AddPatient from "./backend/Hospital/Management/AddPatient";

// import AddDoner from "./backend/Hospital/BloodSystem/admin/AddDoner";
// import AddBlood from "./backend/Hospital/BloodSystem/admin/AddBlood";
// import NewCampaign from "./backend/Hospital/BloodSystem/admin/NewCampaign";
// import MakeAnnouncement from "./backend/Hospital/BloodSystem/admin/MakeAnnouncement";


function App() {
  return (
    <BrowserRouter>
    <ToastContainer position="top-right" />

      <UserAuthContextProvider>
        <Routes>
          {/* Frontend API */}
          <Route path="*" element={<FourZeroFour />} />
          <Route path="/" element={<Home />} />
          <Route path="/education" element={<Education />} />
          <Route path="/news" element={<News />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/support_us" element={<SupportUs />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/videos" element={<ALLvideos />} />
          <Route path="/joining" element={<Joining />} />
          <Route path="/join-us" element={<Joining />} />

          <Route exact path="/c" element={<Join />} />
          <Route path="/chat" element={<Chat />} />
          <Route exact path="/admin-login/c" element={<Join />} />
          <Route path="/admin-login/chats" element={<Chat />} />


          {/* Backend API */}
          <Route
            path="/admin-login"
            element={
              <ProtectedRoute>
                <AdminLogin />
              </ProtectedRoute>
            }
          />
          <Route path="/admin" element={<Admin />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/admin-login/users" element={<UsersAdd />} />
          
          <Route path="admin-login/dashbord" element={<Dashbord />} />
          <Route path="admin-login/dashbord/news" element={<TabNews />} />
          <Route path="admin-login/dashbord/events" element={<AddEvents />} />
          <Route path="admin-login/dashbord/blogs" element={<TabBlogs />} />
          <Route path="admin-login/dashbord/videos" element={<AddVideos />} />
          <Route path="admin-login/dashbord/gallery" element={<AddGallery />} />

          <Route path="admin-login/database" element={<Database />} />
          <Route path="admin-login/database/dmview" element={<DmView />} />
          <Route path="admin-login/database/contactview" element={<ContactView />} />
          <Route path="admin-login/database/volunteerview" element={<VolunteerView />} />

          <Route path="admin-login/hospital" element={<Hospital />} />
          <Route path="admin-login/hospital/website" element={<Website />} />
          <Route path="admin-login/hospital/feedback" element={<Feedback />} />
          <Route path="admin-login/hospital/appointment" element={<Appointment />} />
          <Route path="admin-login/hospital/appointment/view/:id" element={<View />} />
          {/* <Route path="admin-login/hospital/clinic-management" element={<Clinic />} /> */}
          <Route path="admin-login/hospital/clinic-management" element={<AddPatient />} />


          <Route path="/view-announcement" element={<UserViewAnnouncement />} />
          <Route path="/view-campaigns" element={<UserViewCampaigns />} />
          <Route path="/UserBloodAdded" element={<UserBloodAdded />} />
          <Route path="/UserAddBlood" element={<UserAddBlood />} />
          <Route path="/view-blood" element={<UserViewBlood />} />
          <Route path="/user-dashboard" element={<UsersDashboard />} />
          <Route path="/user-login" element={<UsersLogin />} />

          {/* <Route path="/" element={<AdminLogin />} /> */}
          {/* <Route path="/admin-login" element={<AdminLogin />} /> */}
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-dashboard/search" element={<SearchCollection />} />
          
          <Route path="/add-blood" element={<Blood />} />
          <Route path="/blood-update/:id" element={<Blood />} />
          <Route path="/views-blood" element={<ViewBlood />} />

          <Route path="/add-donor" element={<Donor />} />
          <Route path="/view-donor" element={<ViewDoner />} />
          <Route path="/edit-donor" element={<EditDonor />} />
          <Route path="/donor-update/:id" element={<Donor />} />
          <Route path="/delete-donor" element={<DeleteDonor />} />

          <Route path="/make-announcement" element={<Announcement />} />
          <Route path="/announcement-update/:id" element={<Announcement />} />
          <Route path="/views-announcement" element={<ViewAnnouncement />} />

          <Route path="/new-campaign" element={<Campaign />} />
          <Route path="/campaigns-update/:id" element={<Campaign />} />
          <Route path="/views-campaigns" element={<ViewCampaign />} />

          {/* ADD Management */}

          <Route path="/url" element={<Url />} />
          {/* <Route path="/add-blood" element={<AddBlood />} /> */}
          {/* <Route path="/add-donor" element={<AddDoner />} /> */}
          {/* <Route path="/make-announcement" element={<MakeAnnouncement />} /> */}
          {/* <Route path="/new-campaign" element={<NewCampaign />} /> */}
        </Routes>

      </UserAuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
