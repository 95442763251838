import React from 'react'
import AllNewsAdd from "./AllNewsAdd";
import AllNewsView from "./All-News";

const AllNews = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <AllNewsView />
          </div>
          <div className="col-md-3">
            <AllNewsAdd />
          </div>
        </div>
      </div>
    </>
  )
}

export default AllNews
