import React from "react";

const Footer = () => {
  return (
    <>
      {/* <footer className="page-footer font-small special-color-dark pt-4">
        <div className="footer-copyright text-center py-3">
          © 2022
          Developed By SarfarazTech.Com
        </div>
      </footer> */}

      {/* <footer>
        <p>&copy; 2022 Developed By SarfarazTech.Com</p>
    </footer> */}

      <footer
        style={{
          backgroundColor: "#424558",
          bottom: "0",
          left: "0",
          right: "0",
          height: "35px",
          textAlign: "center",
          color: "#ccc",
          position: "fixed",
          float: "inline-end",
        }}
      >
        <p style={{ padding: "10.5px", margin: "0px", lineHeight: "100%", color: "black" }}>
          &copy; 2022 Developed By SarfarazTech.Com
        </p>
      </footer>
    </>
  );
};

export default Footer;
