import React from "react";

export default function Nav({ isOpen }) {
  return (
    <div id="nav" className={isOpen ? "open" : ""}>
      <div className="row menu-top-smd">
        <i className="fa fa-times"></i>
      </div>

      <ul style={{ marginTop: "30px" }} className="menu-itmen-sm">
        <li className="active  mom">
          <a href="/">
            <i className="fa fa-home"></i>
            Home
          </a>
        </li>
        <li className="mom">
          <a
            href="https://www.sunnimohallaclinic.com"
            target={"_blank"}
            rel="noreferrer"
          >
            <i className="fa fa-user-md"></i>
            Hospital
          </a>
        </li>
        <li className="mom">
          <a href="/education">
            <i className="fa fa-user"></i>
            Education
          </a>
        </li>
        <li className="mom">
          <a href="/blogs">
            <i className="fa fa-rss"></i>
            Blogs
          </a>
        </li>
        <li className="mom">
          <a href="/about_us">
            <i className="fa fa-shield"></i>
            About Us
          </a>
        </li>
        <li className="mom">
          <a href="/contact_us">
            <i className="fa fa-map-marker"></i>
            Contact Us
          </a>
        </li>
        <li className="mom">
          <a href="/videos">
            <i className="fa fa-youtube-play"></i>
            Videos
          </a>
        </li>
        <li className="mom">
          <a href="/news">
            <i className="fa fa-newspaper-o"></i>
            News
          </a>
        </li>
        <li className="mom">
          <a href="/gallery">
            <i className="fa fa-photo"></i>
            Gallery
          </a>
        </li>
        <li className="mom">
          <a href="/join-us">
            <i className="fa fa-users"></i>
            Join Us
          </a>
        </li>
        <li className="mom">
          <a href="/support_us">
            <i className="fa fa-heartbeat"></i>
            Support Us
          </a>
        </li>
        <li className="mom">
          <a href="#!" data-toggle="modal" data-target="#myModal">
            <i className="fa fa-money"></i>
            Donate Now
          </a>
        </li>
        <li className="mom">
          <a href="/admin-login">
            <i className="fa fa-sign-in"></i>
            Admin Login
          </a>
        </li>
      </ul>
    </div>
  );
}
