import React from "react";
import data from "../data/data.json";

const FourZeroFour = () => {
  return (
    <>
      <div className="container-fluid _404">
        <div className="row vh-100 bg-light rounded align-items-center justify-content-center">
          <div className="col-md-12 text-center _404">

            {!data.fourzerofour ? (
              <>
              <i className="fa fa-frown-o text-muted" style={{ fontSize: "150px" }}></i>
              <h1 className="text">404</h1>
              </>
             ) : (
               <img style={{width:"25%"}} alt="" src={data.fourzerofour} />
            )}
          
            <h3 className="text-primary sans-serif">Page Not Found <i
              className="fa fa-exclamation-triangle text-primary"
              style={{ fontSize: "21px" }}
            ></i></h3>
            <h5 className="text-primary">
              We’re sorry, the page you have looked for does not exist in our website!
            </h5>
            <a className="btn btn-primary rounded-pill _404btn _404" href="/">
              Go Back To Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FourZeroFour;
