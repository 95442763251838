import React from "react";
import Sidebar from "../adminPanel/Sidebar";
import LatestVideo from "./Latest-Video";
import LatestVideoAdd from "./LatestVideo";

const AddVideos = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "100px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <LatestVideo />
          </div>
          <div className="col-md-3">
            <LatestVideoAdd />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddVideos;
