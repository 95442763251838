import React from "react";
import Sidebar from "../adminPanel/Sidebar";
import AllNews from "./AllNews";
import AddNews from "./News";

const TabNews = () => {
  return (
    <>
      <Sidebar />
      <div className="ml" style={{ marginLeft: "100px" }}>
        <div className="container" style={{ margin: "30px", marginLeft: "10%" }}>
          <ul class="nav nav-tabs">
            <li class="active">
              <a href="#first" data-toggle="tab" id="first_tab">
                All News
              </a>
            </li>
            <li>
              <a href="#second" data-toggle="tab" id="second_tab">
                Latest News
              </a>
            </li>
          </ul>
        </div>

        <div class="tab-content">
          <div id="first" class="tab-pane fade in">
            <AllNews />
          </div>

          <div id="second" class="tab-pane fade in">
            <AddNews />
          </div>
        </div>
      </div>
    </>
  );
};

export default TabNews;
