import { deleteDoc, doc } from "firebase/firestore";
import React from "react";
import { toast } from "react-toastify";
import { db } from "../firebase";

export default function DeleteVideo({ id }) {
  const handleDelete = () => {
    try {
      deleteDoc(doc(db, "LatestVideo", id));
      toast("Latest Video Deleted", { type: "success" });
    } catch (error) {
      toast("Error item not delete", { type: "error" });
      console.log(error);
    }
  };
  return (
    <div>
      <button className="btn btn-danger" onClick={handleDelete}>
        Delete
      </button>
    </div>
  );
}
