import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../adminPanel/Sidebar";
// import MobileMenus from "../../components/MobileMenus";
import { UserLogout } from "../UserLogout";

const Hospital = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "10%" }}>
      <div
        style={{ zIndex: "99999", marginBottom: "10px" }}
        className="container-fluid"
      >
        {/* <MobileMenus /> */}
      </div>

      <div className="container">
        <UserLogout />
        <div className="">
          <h5>WELCOME TO HOSPITAL</h5>
        </div>
        <div className="row">
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="website">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-wordpress"></i>
                </h1>
                <p className="card-text text-center">Website</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="feedback">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-thumbs-o-up"></i>
                </h1>
                <p className="card-text text-center">Feedback</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="appointment">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-calendar"></i>
                </h1>
                <p className="card-text text-center">Appointment</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="/admin-dashboard">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-medkit"></i>
                </h1>
                <p className="card-text text-center">Blood Banks</p>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-xs-4 pb-4 ps-2">
            <Link to="clinic-management">
              <div className="card-body">
                <h1 className="card-title text-center p-4">
                  <i className="fa fa-h-square"></i>
                </h1>
                <p className="card-text text-center">Management</p>
              </div>
            </Link>
          </div>
          
        </div>
      </div>
      <br />
      <br />
      <br />
      </div>
    </>
  );
};

export default Hospital;
