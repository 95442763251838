import { Timestamp } from "firebase/firestore";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CButton from "./CButton";
import DonateBtn from "./DonateBtn";
import Footer from "./Footer";
import Header from "./Header";
import Volunteer from "./Volunteer";

const Joining = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobile: "",
    aadhaar: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    createdAt: Timestamp.now().toDate(),
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { name, email, mobile, aadhaar, address, city, state, pincode } =
      user;

    if (
      name &&
      email &&
      mobile &&
      aadhaar &&
      address &&
      city &&
      state &&
      pincode
    ) {
      const res = await fetch(
        "https://khidmat-foundation-default-rtdb.firebaseio.com/joining.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            mobile,
            aadhaar,
            address,
            city,
            state,
            pincode,
            createdAt: Timestamp.now().toDate(),
          }),
        }
      );

      if (res) {
        setUser({
          name: "",
          email: "",
          mobile: "",
          aadhaar: "",
          address: "",
          city: "",
          state: "",
          pincode: "",
          createdAt: "",
        });

        toast.success("Volunteer Registration Successfully.");
      }
    } else {
      toast.error("Required all fields !");
    }
  };

  return (
    <>
      <Header />
      <section className="ftco-section-3 img">
        <div className="overlay"></div>
        <div className="container">
          <div className="row ">
            <Volunteer />

            <div className="col-md-2 volunteer pl-md-5 ftco-animate fadeInUp ftco-animated text-center"></div>

            <div className="col-md-5 volunteer pl-md-5 ftco-animate fadeInUp ftco-animated text-center hidden-sm visible-lg visible-md">
              <h3 className="mb-3">Be a Donater</h3>
              <form action="#" className="volunter-form">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email "
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Aadhaar Number"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="3"
                    className="form-control"
                    placeholder="Address"
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City "
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State "
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Pincode"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="submit"
                    value="Join Us"
                    className="btn btn-danger py-3 px-5"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <DonateBtn />
      <Footer />
      <CButton />
    </>
  );
};

export default Joining;
