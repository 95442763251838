import React from "react";
import Sidebar from "../adminPanel/Sidebar";
import MobileMenus from "../components/MobileMenus";
import SignUp from "./SignUp";
import { UserLogout } from "./UserLogout";

const UsersAdd = () => {
  return (
    <>
    <Sidebar />
    <div className="ml" style={{ marginLeft: "10%" }}>
      <div
        style={{ zIndex: "99999", marginBottom: "10px" }}
        className="container-fluid"
      >
        {/* <MobileMenus /> */}
      </div>

      <div className="container">
      <UserLogout />
      <SignUp admin="Add New " />
      </div>
      </div>
    </>
  );
};

export default UsersAdd;
