import React from 'react'

const Action = () => {
  return (
    <>
    <div className="container add-tionn hidden-sm visible-lg visible-md">
        <div className="row">
          <div className="col-sm-4">
            <div className="widget-box-inner">
              <div className="round"><a className="inner" href="/join-us"><i className="fa fa-users"></i></a></div>
              <h2>Join Us</h2>
              {/* <p>Since 13-3-2015, Thankam Foundation has placed 19,869 volunteers in 22 programs around the world
                alongside empowering grassroots community projects. </p> */}
            </div>
          </div>

          <div className="col-sm-4">
            <div className="widget-box-inner">
              <div className="round"><a className="inner" href="/support_us"><i className="fa fa-heartbeat"></i></a></div>
              <h2>Support Us</h2>
              {/* <p>Since the launch of the campaign in 13-3-2015, we come a long way in terms of raising awareness of
                charitable gifts in wills amongst both the UK public and Will making professionals.</p> */}
            </div>
          </div>

          <div className="col-sm-4">
            <div className="widget-box-inner">
              <div className="round"><a className="inner" href="/about_us"><i className="fa fa-shield"></i></a></div>
              <h2>About Us</h2>
              {/* <p>This Deed of declaration of trust made on 13th day of March 2015 by Mr.Kingsly. With the sole aim of
                social charitable, religious Promotional, educational and cultural activities. </p> */}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Action